@import 'libs/northstar/src/styles/pxToRem';

.container {
  padding: --px-to-rem(16);

  display: flex;
  flex-direction: column;

  gap: --px-to-rem(8);

  .inputs {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: --px-to-rem(8);

    .amount {
      width: 100%;
    }

    .unit {
      width: 100%;
    }
  }
}

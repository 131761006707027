@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  align-items: center;
  width: --px-to-rem(200);
  height: --px-to-rem(40);
  max-width: --px-to-rem(200);
  padding: --px-to-rem(8) --px-to-rem(10);

  overflow: hidden;

  background-color: var(--background-primary);
  border-radius: --px-to-rem(8);
  transition: background-color 0.2s ease;

  .item {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    gap: --px-to-rem(8);

    .dot {
      width: --px-to-rem(24);
      height: --px-to-rem(24);
    }

    .text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .trigger {
    margin-left: auto;
    transition: color 0.2s ease;
  }

  &--live {
    background-color: var(--green-3);

    .trigger {
      color: var(--green-dark);
    }
  }

  &--sandbox {
    background-color: var(--orange-3);

    .trigger {
      color: var(--orange-dark);
    }
  }
}

.home {
  @apply h-screen w-screen flex flex-col items-center justify-around;
}

.header {
  @apply flex-1 md:flex-none flex justify-around items-center w-full pt-4;
}

.headerButtons {
  @apply flex justify-end gap-2.5;
}

.logo {
  @apply justify-self-center;
}

.main {
  @apply flex-auto md:flex-none flex flex-col items-center w-full mb-0 md:m-auto max-w-[698px] md:px-0 px-6 mt-12;
}

.heading {
  @apply font-gilroy md:text-[48px] md:leading-[56px] text-[28px] leading-[36px] text-[#252731] text-center font-semibold -tracking-[0.02em];
}

.subheading {
  @apply font-gilroy md:text-[48px] md:leading-[56px] text-[28px] leading-[36px] text-primary-1 text-center mb-3 font-semibold;
}

.paragraph {
  @apply font-gilroy font-medium text-gray-1 text-center mb-8 text-[20px] leading-[32px];
}

.paragraph strong {
  @apply font-bold;
}

.footer {
  @apply w-full flex-1 md:flex-none flex flex-col md:flex-row justify-center items-center border-t-[1px] gap-[24px] text-gray-1 py-6 md:py-4 text-[14px];
}

.footerLink {
  @apply !text-[14px];
}

.onboardingIcon {
  @apply flex-1 md:flex-none md:w-full md:h-[340px];
}

.desktopIconContainer {
  @apply w-[80px] h-[80px] rounded-full bg-[#F2F6FF] flex items-center justify-center;
}

.desktopIcon {
  @apply text-primary-1;
}

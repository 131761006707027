@import 'libs/northstar/src/styles/pxToRem';

.container {
  width: 100%;
}

.item {
  padding: --px-to-rem(8) --px-to-rem(12);
  display: flex;
  align-items: center;
  gap: --px-to-rem(8);
  background-color: var(--background-primary);

  transition: background-color 0.2s ease;

  .text {
    color: var(--black-2);
  }

  &:hover {
    outline: none;
    background-color: var(--cloud-0);
  }

  &[data-isselected='true'] {
    background-color: var(--cloud-1);
  }
}

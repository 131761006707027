@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);

  .wrapper {
    padding: --px-to-rem(16) --px-to-rem(12);

    display: flex;
    flex-direction: column;
    gap: --px-to-rem(8);

    border-radius: --px-to-rem(12);
    background: var(--cloud-0);

    .enabled-countries {
      display: flex;
      flex-wrap: wrap;

      align-items: center;
      gap: --px-to-rem(4);

      &:first-child {
        margin-left: --px-to-rem(4);
      }
    }

    .dropdownMenu {
      max-width: 310px;
    }
  }
}

.dropdown {
  border-radius: --px-to-rem(8);
  border: 1px solid var(--cloud-3);
  background-color: var(--background-primary);
  padding-block: --px-to-rem(4);

  overflow: auto;
  max-height: --px-to-rem(300);

  box-shadow: var(--shadow-2);

  box-sizing: content-box;

  label {
    padding-block: --px-to-rem(8);
    padding-inline: --px-to-rem(12);
    display: flex;
    align-items: center;
    gap: --px-to-rem(8);
    background-color: var(--background-primary);

    transition: background-color 0.2s ease;

    color: var(--black-2);

    &:hover {
      outline: none;
      background-color: var(--cloud-0);
    }

    &[data-isselected='true'] {
      background-color: var(--cloud-1);
    }
  }
}

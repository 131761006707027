.container {
  @apply flex px-3 h-8;
}

.container:hover:not(.active) .inner-container {
  @apply text-gray-2;
}

.inner-container {
  @apply text-sm font-medium text-gray-1 transition-all duration-200 border-transparent border-b-2;
}

.active .inner-container {
  @apply text-primary-1 border-primary-1;
}

@import 'libs/northstar/src/styles/pxToRem';

.drawer {
  max-width: 580px;

  .container {
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(24);

    .section {
      display: flex;
      flex-direction: column;
      gap: --px-to-rem(8);
    }
  }
}
.button {
  margin-top: --px-to-rem(8);
}

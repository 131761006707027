.container {
  @apply bg-transparent flex flex-initial h-5 w-9 relative;
}

.label {
  @apply cursor-pointer m-0 p-0;
}

.input {
  @apply opacity-0;
}

.input:checked ~ .handle {
  @apply translate-x-4;
}

.disabled {
  @apply cursor-not-allowed;
}

.input ~ .handle {
  @apply transition-all duration-300;
}

.input:checked ~ .icon {
  @apply translate-x-4;
}

.switch {
  @apply bg-cloud-1 h-4 w-9 block rounded-3xl flex-auto m-0 absolute top-0.5;
}

.handle {
  @apply bg-white rounded-2xl block h-5 m-0 p-0 absolute top-0 transition-transform w-5 border-cloud-1 border;
}

.primary input:checked ~ .switch {
  @apply bg-primary-1;
}

.success input:checked ~ .switch {
  @apply bg-green-2;
}

.icon {
  @apply w-3.5 h-3.5 absolute block left-[2px] top-1/2 -translate-y-1/2 z-10 opacity-0 transition-opacity duration-300;
}

.icon--visible {
  @apply opacity-100;
}

.primary .icon path {
  @apply fill-primary-1;
}

.success .icon path {
  @apply fill-green-2;
}

.locked .icon path {
  @apply fill-gray-2;
}

.control {
  @apply flex w-full items-center justify-between px-3 py-[14px] rounded-lg border border-cloud-2 gap-2 min-h-[48px];
}

.control:hover:not(.disabled),
.control[data-state='open'] {
  @apply !border-primary-1;
}

.control:hover:not(.disabled) svg,
.control[data-state='open'] svg {
  @apply text-primary-1;
}

.control[data-state='open'] svg {
  @apply rotate-180;
}

.control.disabled {
  @apply bg-cloud-0;
}

.control.disabled svg {
  @apply text-gray-2;
}

.control.error {
  @apply text-red-1 border-red-1 bg-red-4;
}

.control.error svg {
  @apply text-red-1;
}

.content {
  @apply flex items-center gap-2;
}

.content.placeholder {
  @apply text-gray-2 font-normal text-xs;
}

.error .content {
  @apply text-red-1;
}

.list-container {
  @apply w-full py-3;
}

.list {
  @apply bg-white border rounded-lg py-2 max-h-64 w-full overflow-y-scroll;

  box-shadow: 0 8px 12px -6px rgb(24 39 7 / 6%),
    0 8px 12px -3px rgb(24 39 7 / 6%);
}

.list--item {
  @apply py-2 px-4 cursor-pointer flex gap-3 items-center;
}

.list--item:hover {
  @apply bg-cloud-0;
}

.list--item.selected {
  @apply !bg-transparent;
}

.list--item.selected .content {
  @apply text-primary-1;
}

.option {
  @apply flex items-center gap-2;
}

.option svg {
  @apply w-4 h-4;
}

.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 p-6;
}

.header {
  @apply flex justify-between items-start text-black-2 mb-4 text-lg font-bold;
}

.tabTrigger {
  @apply px-4 py-3 text-sm text-gray-1 font-bold radix-state-active:text-primary-1 radix-state-active:border-b-2 radix-state-active:border-primary-1;
}

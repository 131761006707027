@import 'libs/northstar/src/styles/pxToRem';

.logo {
  width: 100%;
  max-width: --px-to-rem(24);
  max-height: --px-to-rem(24);
  min-width: --px-to-rem(24);
  min-height: --px-to-rem(24);

  display: flex;
  align-items: center;
  justify-content: center;

  &__img {
    width: 100%;
    height: auto;
  }

  &--char {
    height: --px-to-rem(24);
    width: --px-to-rem(24);

    color: #ffffff;
    text-transform: uppercase;

    background-color: var(--primary-1);
    border-radius: --px-to-rem(4);
  }
}

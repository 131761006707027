@import 'libs/northstar/src/styles/pxToRem';

.card_content {
  padding: --px-to-rem(16) --px-to-rem(16);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.input_container {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(16);
}

.input {
  display: flex;
  flex: 1;
}

.remove_button {
  display: flex;
}

.alert {
  max-width: --px-to-rem(500);
}

.container {
  transform: translate(-50%, 60px) !important;
  left: 50% !important;
  overflow: visible !important;

  @apply fixed top-[56px] z-20 overflow-hidden w-full rounded-xl flex justify-center;
}

.content {
  @apply bg-white p-6 rounded-xl max-w-[461px];

  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%),
    0 8px 12px -3px rgb(24 39 75 / 6%);
}

.title {
  @apply font-bold;
}

.message {
  @apply mr-4 mt-2;
}

.cancel {
  @apply mr-3;
}

.cta {
  @apply flex justify-end mt-4;
}

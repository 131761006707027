.successIcon {
  @apply mr-2 text-green-1 p-1.5 rounded-full bg-green-3;
}

.failureIcon {
  @apply mr-2 text-red-1 p-1.5 rounded-full bg-red-3;
}

.failureIcon svg path {
  @apply fill-red-1;
}

.sectionHeader {
  @apply bg-cloud-0;
}

.messageList {
  @apply overflow-clip rounded-xl border;
}

.messageHeader {
  @apply relative divide-y overflow-hidden;
}

.expended {
  @apply !rotate-180;
}

.table {
  @apply text-black-2 min-w-full;
}

.table_head {
  @apply bg-white;
}

.cell_header {
  @apply px-4 h-9 text-left font-medium text-gray-2 uppercase tracking-wider border border-cloud-2;

  font-size: 0.625rem;
}

.table_body {
  @apply bg-white;
}

.table_body tr {
  @apply hover:bg-cloud-0;
}

.table_body .table__info-banner--top {
  @apply !p-0 border border-cloud-2;
}

.table_body .table__info-banner--top .table__info-banner--top__wrapper {
  @apply flex gap-x-1 bg-primary-4 w-full py-2 px-3 text-start;
}

.table_body .table__info-banner--top .table__info-banner--top__wrapper a {
  @apply text-xs text-primary-1;
}

.cell_data {
  @apply whitespace-nowrap border border-cloud-2 h-[34px] text-xs;
}

.no_data {
  @apply text-gray-1 text-sm pt-6 font-medium mr-auto;
}

.sort {
  @apply rounded-md h-5 w-5 flex items-center justify-center ml-1.5;
}

.sort--active {
  @apply bg-cloud-2;
}

.sort--active svg path:last-of-type {
  @apply fill-primary-1;
}

.sort--desc {
  @apply transform rotate-180;
}

.dropdown {
  @apply w-[220px] relative z-10;
}

.dropdown > button {
  @apply py-[10px];
}

.dropdown svg {
  @apply w-[10px];
}

.dropdownContent {
  @apply bg-white border rounded-lg py-2;

  box-shadow: 0 8px 12px -6px rgb(24 39 7 / 6%),
    0 8px 12px -3px rgb(24 39 7 / 6%);
}

.dropdownItem {
  @apply items-center px-3 py-[11px] hover:bg-cloud-1 focus:bg-cloud-1 font-medium text-sm w-[220px];
}

.trigger {
  @apply text-black text-sm;
}

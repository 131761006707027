.container {
  @apply flex flex-col gap-2 py-2 h-full;
}

.row {
  @apply flex items-center justify-between;
}

.row-leading {
  @apply max-w-[113px] w-full h-5 bg-cloud-0;
}

.row-trailing {
  @apply h-5 w-7 bg-cloud-0;
}

.circle-container {
  @apply flex items-center justify-center grow;
}

.circle {
  @apply rounded-full w-56 h-56 bg-cloud-0;
}

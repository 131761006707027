.search-container {
  @apply relative;
}

.search {
  @apply text-xs h-8 block border border-cloud-2 outline-cloud-2 pl-8;

  width: 100%;
  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
  border-radius: 44px;
}

.search::placeholder {
  @apply text-xs text-gray-2;
}

.description__container {
  @apply my-4;
}

.description {
  @apply text-sm text-gray-1;
}

.avatar svg {
  @apply w-6 h-6;
}

.icon {
  @apply absolute top-1/2 left-2.5 transform -translate-y-1/2;
}

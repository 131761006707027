.backdrop {
  @apply absolute w-full h-full bg-black-1/[.15] backdrop-opacity-50 top-0 cursor-default z-10;
}

.backdrop__clickable {
  @apply cursor-pointer;
}

.container {
  @apply z-20 max-w-[1400px];

  overflow: scroll;
}

.container::-webkit-scrollbar {
  display: none;
}

.container::-webkit-scrollbar-track {
  display: none;
}

.container::-webkit-scrollbar-thumb {
  display: none;
}

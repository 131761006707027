.container {
  @apply grid grid-cols-12 gap-2 rounded-xl border border-cloud-2 p-4 items-center my-2;
}

.chain {
  @apply col-span-4 flex items-center;
}

.name {
  @apply text-sm text-black-2;
}

.description {
  @apply text-xs text-gray-1;
}

.toggle {
  @apply col-span-2 m-0;
}

.toggle-empty {
  @apply col-span-2 m-0 text-gray-1;
}

.icon {
  @apply mr-4;
}

.icon svg,
.icon img {
  @apply w-6 h-6;
}

.unique-copy.unique-copy {
  @apply ml-1 text-gray-1 text-[0.675rem];
}

.container {
  @apply flex flex-col w-full;
}

.wrapper {
  @apply flex flex-1 mx-auto justify-center w-full pr-8;
}

.overview {
  @apply flex flex-1;
}

.portal {
  @apply absolute w-[460px] bg-white p-6 rounded-2xl;

  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  overflow: visible !important;
  box-shadow: 0 8px 12px -3px rgb(24 39 75 / 6%);
  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%);
}

.backdrop {
  @apply bg-black-1/[.70];
}

@import '../../../../styles/pxToRem';

.container {
  border-radius: --px-to-rem(8);
  border: 1px solid var(--cloud-3);
  background-color: var(--background-primary);

  overflow: hidden;
  box-shadow: var(--shadow-2);

  box-sizing: content-box;

  display: flex;
  flex-direction: column;
}

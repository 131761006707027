.heading {
  @apply mb-2 text-black-2;
}

.description {
  @apply mb-3 text-gray-2;
}

.input {
  @apply mb-6 flex-none max-w-[357px];
}

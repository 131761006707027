.accordion-item {
  @apply flex items-start gap-x-3 w-full border-[1px] border-cloud-2 rounded-xl mb-3 p-4 shadow-sm cursor-pointer;
}

.accordion-item--icon {
  @apply flex justify-center items-center rounded-full text-sm w-8 h-8 p-4 font-bold bg-primary-3 text-primary-1;
}

.quick-start-stepper__copy-wrapper,
.quick-start-stepper__accordion--trigger {
  @apply w-full;
}

.accordion-item--title {
  @apply text-black-2 text-xl;
}

.accordion-item--content {
  @apply hidden;
}

.accordion-item--content .stepper-tabs {
  @apply w-full;
}

.stepper-tabs .stepper-tabs__subtitle {
  @apply text-gray-1 text-sm mb-3;
}

.stepper-tabs__subtitle .stepper-tabs__subtitle__link {
  @apply text-primary-1;
}

.accordion-item--content .stepper-tabs .tab-trigger {
  @apply px-4 py-3 text-sm text-gray-1 font-bold radix-state-active:text-primary-1 radix-state-active:border-b-2 radix-state-active:border-primary-1;
}

.accordion-item--content .stepper-tabs .tab-content[data-state='active'] {
  @apply flex items-center py-3;
}

.accordion-item--content .stepper-tabs .single-key {
  @apply bg-black-2 items-center min-w-fit;
}

.accordion-item--content .stepper-tabs .single-key p {
  @apply text-white whitespace-pre-wrap;
}

.accordion-item--content .stepper-tabs .single-key button {
  @apply hover:bg-gray-0 rounded-md;
}

.accordion-item--content .stepper-tabs .single-key svg path {
  @apply fill-white;
}

.accordion-item--last {
  @apply relative flex justify-between;
}

.accordion-item--last .accordion-item--last-content {
  @apply w-3/4;
}

.accordion-item--last-content .accordion-item--last-content__counter {
  @apply !text-cloud-2 h-9 flex items-center;
}

.accordion-item--last .accordion-item--last-content .label {
  @apply !text-gray-1 mt-1 mb-5;
}

.accordion-item--last .accordion-item--last-img {
  @apply absolute bottom-[-1rem] right-[-0.5rem];
}

/* completed step */
.accordion-item--completed .accordion-item--icon {
  @apply bg-green-4 p-0;
}

.accordion-item--completed .accordion-item--icon svg {
  @apply m-auto w-6 h-6;
}

.accordion-item--completed .accordion-item--icon svg path {
  @apply fill-green-2;
}

.accordion-item--completed .accordion-item--title {
  @apply text-gray-1 font-medium;
}

.accordion-item--completed .accordion-item--subtitle {
  @apply hidden;
}

/* active */
.accordion-item--active .accordion-item--content {
  @apply block;
}

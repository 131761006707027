@import 'libs/northstar/src/styles/pxToRem';

.content {
  border-radius: --px-to-rem(8);
  padding: --px-to-rem(4) --px-to-rem(8);

  &.default {
    background-color: var(--dynamic-tooltip-color);
    color: var(--dynamic-tooltip-text-color);
  }
}

/** Styles for the arrow SVG **/
.arrow {
  fill: var(--dynamic-tooltip-color);
}

.backdrop {
  @apply fixed w-full h-full bg-black-1/[.60] top-0 left-0 cursor-default z-[1000];
}

.backdrop__clickable {
  @apply cursor-pointer;
}

.container::-webkit-scrollbar {
  display: none;
}

.container::-webkit-scrollbar-track {
  display: none;
}

.container::-webkit-scrollbar-thumb {
  display: none;
}

.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  overflow-y: auto;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

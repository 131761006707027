.outcomes {
  @apply my-4 flex flex-col gap-4;
}

.outcome {
  @apply flex flex-col gap-1;
}

.outcome__rules {
  @apply border border-cloud-2 px-4 rounded-xl flex-col flex mt-2;
}

.outome__rule {
  @apply py-2 first:pt-4 last:pb-4;
}

.outcome__divider > div:first-child {
  @apply w-12;
}

.container {
  @apply flex flex-col overflow-auto flex-1;

  width: calc(100% - 245px - 32px);
}

.project_selector {
  @apply w-full mb-2;
}

.environment_selector {
  @apply w-full mb-2;
}

.filters-section {
  @apply flex items-center justify-between pb-3;
}

.table__container {
  @apply w-full overflow-auto pb-1;
}

/* width */
.table__container::-webkit-scrollbar {
  @apply h-1;
}

/* Track */
.table__container::-webkit-scrollbar-track {
  @apply bg-cloud-1;
}

/* Handle */
.table__container::-webkit-scrollbar-thumb {
  @apply bg-cloud-2 rounded-3xl;
}

/* Handle on hover */
.table__container::-webkit-scrollbar-thumb:hover {
  @apply bg-cloud-2;
}

.alert {
  @apply mb-3;
}

.alert--link {
  @apply text-primary-1;
}

@import 'libs/northstar/src/styles/pxToRem';

.icon {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.icon-color-primary-1 {
  color: var(--primary-1);
}

.icon-color-primary-2 {
  color: var(--primary-2);
}

.icon-color-gray-1 {
  color: var(--gray-1);
}

.icon-color-gray-2 {
  color: var(--gray-2);
}

.icon-color-orange-dark {
  color: var(--orange-dark);
}

.icon-color-red-1 {
  color: var(--red-1);
}

.icon-color-green-dark {
  color: var(--green-dark);
}

.icon-color-white {
  color: var(--white);
}

.icon-color-black-2 {
  color: var(--black-2);
}

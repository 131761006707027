.top-area {
  @apply mb-4;
}

.top-area .top-area__heading--main {
  @apply text-[22px] leading-7;
}

.top-area .top-area__heading--sub {
  @apply text-gray-1;
}

.accordion__container {
  @apply flex flex-col;
}

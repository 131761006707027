@import 'libs/northstar/src/styles/pxToRem';

.content {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);

  .url {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    gap: --px-to-rem(4);
    overflow: hidden;

    &:hover .copy {
      opacity: 1;
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .copy {
      min-width: --px-to-rem(12);
      opacity: 0;
      transition: all 100ms;
    }
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.portal {
  position: absolute;
  width: 460px;
  background-color: var(--background-primary);
  padding: --px-to-rem(24);
  border-radius: --px-to-rem(26);

  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  .content {
    margin-top: --px-to-rem(32);
    margin-bottom: --px-to-rem(48);

    display: flex;
    flex-direction: column;

    gap: --px-to-rem(4);
  }

  .cta {
    width: 100%;
  }
}

.container {
  @apply w-full;
}

.indicator {
  @apply ml-8 px-3 py-1.5 w-max max-w-max rounded-b-lg text-xs transition-all duration-200;
}

.indicator--capitalize {
  @apply capitalize;
}

.indicator--sandbox {
  @apply text-white bg-orange-1;
}

.indicator--live {
  @apply bg-primary-3 text-primary-1;
}

.divider {
  @apply h-1 w-full relative z-[100] transition-all duration-200;
}

.divider--sandbox {
  @apply bg-orange-1;
}

.divider--live {
  @apply bg-primary-3;
}

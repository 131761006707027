.right-sidebar {
  @apply max-w-[292px] flex justify-end border-cloud-1 flex-1;
}

.quickhelp__wrapper {
  @apply flex flex-col justify-between;
}

.quickhelp__wrapper .onboarding-progress__container {
  @apply flex flex-col;
}

.quickhelp__book-a-meeting {
  @apply bg-primary-3 p-4 flex flex-col items-center justify-center rounded-xl mt-4;
}

.book-a-meeting__paragraph {
  @apply text-center text-gray-1;
}

.book-a-meeting__subtitle {
  @apply mb-3 mt-[2px] text-center;
}

.book-a-meeting__button {
  @apply w-full text-black-2 bg-white border-cloud-2 py-[9px] relative rounded-md border-[1px] px-[12px] text-xs font-bold leading-4 transition-all duration-300 overflow-hidden gap-1.5 flex items-center justify-center;

  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
}

.book-a-meeting__button:hover:not(:active):not(:disabled) {
  @apply bg-cloud-0 border-cloud-2;

  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%),
    0 8px 12px -3px rgb(24 39 75 / 6%);
}

.quickhelp__wrapper .onboarding-progress__head,
.quickhelp__wrapper .onboarding-progress__body {
  @apply flex flex-col items-center p-4 border border-cloud-2;
}

.quickhelp__wrapper .onboarding-progress__head {
  @apply rounded-t-xl;
}

.quickhelp__wrapper .onboarding-progress__body {
  @apply rounded-b-xl border-t-0 gap-y-2;
}

.quickhelp__wrapper .onboarding-progress__body .onboarding-progress__info {
  @apply flex items-center gap-x-1;
}

.quickhelp__wrapper .onboarding-progress__body button {
  @apply w-full;
}

.quickhelp__wrapper .onboarding-progress__body .onboarding-progress__info svg {
  @apply w-[.875rem] h-[.875rem];
}

.quickhelp__wrapper
  .onboarding-progress__body
  .onboarding-progress__info
  svg
  path {
  @apply fill-gray-2;
}

.quickhelp__bottom-links {
  @apply flex flex-col;
}

.quickhelp__bottom-links .quickhelp__bottom-links--wrapper {
  @apply flex flex-col gap-y-2 mb-4;
}

.quickhelp__bottom-links--label {
  @apply text-xs text-gray-1;
}

.quickhelp__bottom-links button {
  @apply flex items-center justify-start gap-x-2 !w-fit;
}

.quickhelp__bottom-links button svg {
  @apply !w-4 !h-4;
}

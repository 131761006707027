@import 'libs/northstar/src/styles/pxToRem';

.content {
  padding: --px-to-rem(16);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.table {
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }

  .copyTextWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: --px-to-rem(4);
    max-width: 100%;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(12);
}

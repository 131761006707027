.control {
  @apply h-8 px-3.5 border border-cloud-2 rounded-md flex items-center justify-center text-xs text-black-2 font-bold;

  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
}

.item {
  @apply flex items-center h-8 pl-4 pr-1.5 cursor-pointer text-xs text-black-2 font-medium;
}

.item:hover {
  @apply bg-cloud-0;
}

.item__text {
  @apply lowercase;
}

.item__text::first-letter {
  @apply capitalize;
}

@import '../../../../../../libs/northstar/src/styles/pxToRem';

.backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.portal {
  position: absolute;
  width: 460px;
  background-color: var(--background-primary);
  padding: --px-to-rem(32);
  border-radius: --px-to-rem(16);

  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  .content {
    display: flex;

    flex-direction: column;
    gap: --px-to-rem(24);

    .actions {
      display: flex;

      flex-direction: column;
      gap: --px-to-rem(4);
      padding-left: --px-to-rem(7);
    }
  }
}

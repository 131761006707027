@import 'libs/northstar/src/styles/pxToRem';

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: --px-to-rem(8);
}

.input {
  flex: 1;
}

.container {
  @apply relative mb-2 flex flex-auto;
}

.textarea {
  resize: none;

  @apply px-3 pt-[21px] pb-[7px] m-0 rounded-lg border border-cloud-2 w-full text-black-2 text-sm font-medium text-xs placeholder-transparent;
}

.label {
  transition: all 0.2s ease;

  @apply absolute top-2 left-3 text-[10px] leading-[12px] text-gray-1;
}

.optional {
  transform: translateY(-50%);
  @apply absolute top-1/2 right-[14px] text-xs leading-[12px] text-gray-2;
}

.textarea-error {
  @apply border-red-1 bg-red-4;
}

.label-error {
  @apply text-red-1;
}

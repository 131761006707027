@import '../../styles/pxToRem';

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.4);
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  width: --px-to-rem(460);
  max-width: 95vw;
  max-height: 90vh;
  background-color: var(--background-primary);
  border-radius: --px-to-rem(16);
  z-index: 1000;
  padding: --px-to-rem(32);
  gap: --px-to-rem(16);
}

.dialog.small {
  padding: --px-to-rem(16);
}

.dialog.large {
  padding: --px-to-rem(32);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.selectItem {
  @apply py-2 px-4 cursor-pointer flex gap-3 items-start;
}

.selectItem:hover {
  @apply bg-cloud-0;
}

.selectItem[data-highlighted] {
  outline: none;
}

@import 'libs/northstar/src/styles/pxToRem';

.button {
  display: flex;
  align-items: center;
  gap: --px-to-rem(8);
  padding: --px-to-rem(8) --px-to-rem(12);
  width: 100%;

  color: var(--black-2);

  background-color: var(--background-primary);

  margin-top: --px-to-rem(3);
  border-top: 1px solid var(--cloud-3);

  transition: background-color 0.2s ease, color 0.2s ease;

  .icon {
    color: var(--gray-2);

    transition: color 0.2s ease;
  }

  &:hover {
    background-color: var(--cloud-0);

    .icon {
      color: var(--gray-1);
    }
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
}

.content {
  padding: --px-to-rem(16);

  background: var(--cloud-0);
}

.tag {
  text-transform: uppercase;
}

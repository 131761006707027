@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  align-items: center;
  width: --px-to-rem(200);
  height: --px-to-rem(40);
  max-width: --px-to-rem(200);
  padding: --px-to-rem(10) --px-to-rem(8) --px-to-rem(10) --px-to-rem(12);

  overflow: hidden;

  gap: --px-to-rem(2);

  background-color: var(--background-primary);
  border-radius: --px-to-rem(8);
  transition: background-color 0.2s ease;

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .trigger {
    margin-left: auto;
    color: var(--gray-2);
  }

  &:hover {
    background-color: var(--cloud-0);

    .trigger {
      color: var(--gray-1);
    }
  }
}

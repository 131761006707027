@import 'libs/northstar/src/styles/pxToRem';

.card_content {
  padding: --px-to-rem(16) --px-to-rem(16);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
}

.alert_description {
  max-width: --px-to-rem(500);
}

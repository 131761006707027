.container {
  @apply flex w-full items-center justify-between px-3 py-[14px] rounded-lg border border-cloud-2 gap-2;
}

.container:hover:not(.disabled) {
  @apply border-primary-1;
}

.icon {
  @apply h-3 w-3;
}

.container:hover:not(.disabled) .icon path {
  @apply fill-primary-1;
}

.container[data-state='open'] {
  @apply border-primary-1;
}

.container[data-state='open'] .icon {
  @apply rotate-180;
}

.container[data-state='open'] .icon path {
  @apply fill-primary-1;
}

.content {
  @apply flex items-center gap-2 text-gray-2;
}

.content svg {
  @apply w-4 h-4;
}

.disabled {
  @apply bg-cloud-0;
}

.disabled .icon path {
  @apply fill-gray-2;
}

.container-error {
  @apply text-red-1 border-red-1 bg-red-4;
}

.content-error {
  @apply text-red-1;
}

.container {
  @apply flex max-h-screen w-full;
}

.section__left {
  @apply bg-white w-[30%] px-8 py-6 text-lg text-gray-2;
}

.section__right {
  @apply bg-white w-[70%];
}

.buttons {
  @apply flex gap-3 justify-end mt-4;
}

.buttons button:only-child {
  @apply mr-auto;
}

.button {
  @apply w-full mt-4;
}

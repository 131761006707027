@import 'libs/northstar/src/styles/pxToRem';

.tabTrigger {
  @apply px-4 py-3 text-sm text-gray-1 font-bold radix-state-active:text-primary-1 radix-state-active:border-b-2 radix-state-active:border-primary-1;
}

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(24);
  flex: 1;
}

// ChainTokenConfiguration styles
.chainConfig {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);
}

.chainHeader {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(8);
  align-items: center;
}

.chainName {
  font-weight: 700;
  font-size: --px-to-rem(14);
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(8);
  min-width: 100%;
}

.dropdown {
  background-color: #555A7C08;
  width: 100%;
}

.otherInput {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(8);
  min-width: 100%;
}

// ToggleButtons styles
.toggleContainer {
  display: flex;
  background-color: rgb(243 244 246);
  border-radius: --px-to-rem(8);
  width: 100%;
  gap: --px-to-rem(8);
  padding: --px-to-rem(16);
}

.toggleButton {
  display: flex;
  align-items: center;
  border: 1px solid rgb(209 213 219);
  border-radius: --px-to-rem(12);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 50%;
  background-color: white;
  color: rgb(17 24 39);
  padding: --px-to-rem(12) --px-to-rem(16);
}

.toggleDot {
  width: --px-to-rem(16);
  height: --px-to-rem(16);
  border-radius: 9999px;
  margin-right: --px-to-rem(8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.toggleDotSelected {
  background-color: #09916B;
  border-color: rgb(34 197 94);
}

.toggleDotUnselected {
  background-color: white;
  border: 1px solid rgb(156 163 175);
}

.toggleIcon {
  width: --px-to-rem(12);
  height: --px-to-rem(12);
  color: white;
}

// ExternalFundingSection styles
.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(24);
}

.drawer {
  max-width: 580px;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
  padding: --px-to-rem(8);
}

.chainConfigContainer {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
  padding: --px-to-rem(16);
  background-color: #555A7C08;
}

.alertContainer {
  padding: --px-to-rem(16);
}

@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.dynamicV2Section {
  padding: --px-to-rem(16);
  background: var(--cloud-0);
  border-radius: --px-to-rem(12);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);

  h3 {
    color: var(--black-2);
    font-size: --px-to-rem(14);
    font-weight: 600;
    margin-bottom: --px-to-rem(4);
  }

  p {
    color: var(--gray-1);
    font-size: --px-to-rem(14);
    margin-bottom: --px-to-rem(16);
  }

  button {
    align-self: flex-start;
  }
}

.title {
  display: flex;
  gap: --px-to-rem(4);
}

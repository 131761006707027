@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);

  .new-item {
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(8);
    width: 100%;

    .row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex: 1;
      gap: --px-to-rem(12);
    }
  }

  .add-button {
    align-self: flex-start;
    min-width: --px-to-rem(126);
  }
}

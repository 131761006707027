@import 'libs/northstar/src/styles/pxToRem';

.container {
  width: 100%;
  border-bottom: 1px solid var(--cloud-3);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: var(--background-primary);

  display: flex;
  justify-content: space-between;

  .tabs {
    position: relative;

    display: flex;
    gap: --px-to-rem(12);

    .tab {
      padding-bottom: --px-to-rem(10);

      &__inner {
        border-radius: --px-to-rem(6);
        background: var(--background-primary);

        padding: --px-to-rem(4) --px-to-rem(6);
        color: var(--gray-1);

        transition: all 0.2s ease;

        &--active {
          color: var(--black-2);
        }

        &--disabled {
          cursor: not-allowed;
        }

        &:hover {
          background: var(--cloud-1);
        }
      }
    }

    .bubble {
      position: absolute;
      bottom: -1px;
      border-bottom: 2px solid var(--black-2);
      transition: all 0.3s ease 0.1s;
    }
  }

  .info {
    display: flex;
    gap: --px-to-rem(10);
    align-items: center;
    height: max-content;
    padding-block: --px-to-rem(4);

    .action {
      height: max-content;
    }
  }
}

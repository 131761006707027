.text {
  @apply text-black-2 text-left;
}

.regular {
  @apply font-normal;
}

.medium {
  @apply font-medium;
}

.bold {
  @apply font-bold;
}

.code-0 {
  @apply font-roboto-sans font-medium text-xs tracking-tight leading-[18px];
}

.code-1 {
  @apply font-roboto-sans font-medium text-sm tracking-tight leading-[18px];
}

.code-2 {
  @apply font-roboto-sans font-medium text-sm tracking-tight leading-[16px];
}

.anchor {
  @apply tracking-wide;
}

.paragraph-0 {
  @apply text-[10px] leading-[13px];
}

.paragraph-1 {
  @apply text-xs leading-[18px];
}

.paragraph-2 {
  @apply text-sm leading-[18px];
}

.paragraph-3 {
  @apply text-base leading-[18px];
}

.title {
  @apply text-[22px] leading-[29px];
}

.subtitle {
  @apply text-[17px] leading-[22px];
}

.numbers {
  @apply text-[28px] leading-9 font-bold;
}

.gray-1 {
  @apply text-gray-1;
}

.gray-2 {
  @apply text-gray-2;
}

.primary-1 {
  @apply text-primary-1;
}

.inherit {
  @apply text-inherit;
}

.icon {
  @apply transition-colors duration-300;
}

.icon-color-text-primary-1 {
  @apply text-primary-1;
}

.icon-color-text-primary-2 {
  @apply text-primary-2;
}

.icon-color-text-tertiary {
  @apply text-tertiary;
}

.icon-color-gray-1 {
  @apply text-gray-1;
}

.icon-color-gray-2 {
  @apply text-gray-2;
}

.icon-size-medium {
  @apply w-4 h-4;
}

.icon-size-large {
  @apply w-6 h-6;
}

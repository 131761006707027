.container {
  @apply flex items-center justify-between w-full;
}

.token {
  @apply text-gray-1 text-xs overflow-hidden text-ellipsis max-w-[220px];
}

button.button--edit svg {
  @apply w-4 h-4;
}

button.button--edit svg path {
  @apply fill-gray-1;
}

.container {
  @apply pt-1 pb-1 w-max rounded-full;
}

.container--big {
  @apply px-2.5 text-sm;
}

.container--small {
  @apply px-2 text-[0.625rem];
}

.color--green {
  @apply bg-green-4 text-green-1;
}

.color--yellow {
  @apply bg-yellow-4 text-yellow-1;
}

.color--red {
  @apply bg-red-4 text-red-1;
}

.color--primary {
  @apply bg-primary-3 text-primary-1;
}

.color--secondary {
  @apply bg-cloud-1 text-gray-1;
}

.color--white {
  @apply bg-white text-gray-1;
}

.fields {
  @apply flex flex-1;
}

.fields > * {
  @apply mr-4;
}

.field {
  @apply max-w-[286px] h-full mb-0;
}

.field > input:disabled {
  cursor: not-allowed;
}

.controls {
  @apply flex;
}

.controls > * {
  @apply mr-2;
}

.controls:last-child {
  @apply mr-0;
}

.dropdownContent {
  @apply bg-white border rounded-lg py-2;

  box-shadow: 0 8px 12px -6px rgb(24 39 7 / 6%),
    0 8px 12px -3px rgb(24 39 7 / 6%);
}

.dropdownItem {
  @apply min-w-[140px] px-4 py-2 hover:bg-cloud-1 focus:bg-cloud-1 font-medium text-sm;
}

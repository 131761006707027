.container {
  position: relative;
  width: 100%;
}

.date-input {
  @apply text-black-2 border border-cloud-1 rounded-3xl text-xs w-full font-medium px-3 py-2 h-[34px];
}

.date-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.date-input:placeholder-shown {
  @apply bg-cloud-0;
}

.calendar-icon-container {
  @apply pr-3 text-gray-2;

  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

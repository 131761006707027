@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');

@import '@fontsource/dm-sans/latin-400.css';
@import '@fontsource/dm-sans/latin-400-italic.css';
@import '@fontsource/dm-sans/latin-500.css';
@import '@fontsource/dm-sans/latin-500-italic.css';
@import '@fontsource/dm-sans/latin-700.css';
@import '@fontsource/dm-sans/latin-700-italic.css';

@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url('../assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

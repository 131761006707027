.container {
  @apply flex gap-3 items-center py-3 px-5;
}

.icon svg {
  @apply w-6 h-6;
}

.icon--gray svg path {
  @apply fill-gray-2;
}

.advanced_description {
  @apply !text-primary-1 text-xs;
}

.wrapper {
  @apply flex flex-col;
}

.wrapper__text {
  @apply text-[14px];
}

.wrapper__description {
  @apply text-gray-2 text-xs;
}

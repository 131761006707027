@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: --px-to-rem(24);
}

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(24);
}

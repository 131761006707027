.responseContainer {
  @apply mb-5;

  margin-left: 15px;
}

.successMessage {
  @apply text-green-600;
}

.errorMessage {
  @apply lowercase text-red-1;
}

.errorMessage::first-letter {
  @apply uppercase;
}

.heading {
  @apply mb-1;
}

.successHeading {
  @apply text-green-600;
}

.errorHeading {
  @apply text-red-600;
}

.errorList {
  @apply list-disc pl-5 mt-2;
}

.errorItem {
  @apply text-red-1;
}

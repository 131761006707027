.portal {
  transform: translate(-50%, 60px) !important;
  left: 50% !important;
  overflow: visible !important;

  @apply absolute w-[460px] bg-white p-6 left-0 top-0 rounded-2xl;
}

.buttonRow {
  @apply mt-6 flex w-full space-x-3;
}

.field-item--icon {
  @apply flex justify-center items-center rounded-full text-sm w-6 h-6 font-bold bg-primary-3 text-primary-1;
}

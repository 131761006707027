.container {
  @apply flex relative items-center border max-h-[23px] border-cloud-2 rounded-md px-1.5 font-medium tracking-tight bg-white py-3;

  font-size: 0.625rem;
}

.address {
  @apply font-roboto-sans text-[10px] leading-[13px] font-medium tracking-tight;
}

.icon {
  @apply w-3 h-3 mr-1;
}

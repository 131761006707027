.container {
  @apply bg-red-4 p-3 font-medium rounded-lg;
}

.heading {
  @apply text-red-1 text-sm font-bold mb-1;
}

.content {
  @apply text-red-1 font-bold text-xs;
}

.pill {
  padding: 3px 6px 3px 4px;
  background-color: var(--cloud-3);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 3px;

  .chains {
    display: flex;
    align-items: center;

    .icon {
      padding: 1px;
      background-color: var(--cloud-3);
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: -5px;
      }
    }

    .amount {
      padding-top: 2px;
    }
  }
}

.container {
  @apply bg-primary-4 px-3 rounded-lg w-full min-w-[447px] max-h-[93px] py-[11px] flex justify-between;
}

.container--extended,
.container--extended .key__container {
  @apply max-h-fit;
}

.key__container {
  @apply w-full overflow-auto max-h-[93px];
}

/* override hightlight.js container style */
.key__container pre {
  @apply !w-full;
}

.key__container code {
  @apply !bg-transparent !contents !p-0;
}

/* end override hightlight.js container style */

.key {
  @apply text-primary-1;
}

.key::-webkit-scrollbar {
  @apply hidden;
}

.container {
  @apply text-gray-1 text-sm my-0 text-left flex;
}

.container--half {
  @apply flex flex-col w-1/2;
}

.wrapper {
  @apply mt-2 flex;
}

.value {
  @apply text-[28px] font-bold text-primary-1;
}

.iconsContainer {
  @apply flex justify-around items-center gap-x-3 ml-3;
}

.icon {
  @apply max-w-[12px] max-h-3;
}

.button {
  @apply mt-2;
}

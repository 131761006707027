@import 'libs/northstar/src/styles/pxToRem';

.jwtTest {
  padding: --px-to-rem(16);
  border-radius: --px-to-rem(12);
  background: #fafafb;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: --px-to-rem(10);
}

.heading {
  margin-bottom: --px-to-rem(8);
}

.description {
  font-size: --px-to-rem(14);
  line-height: --px-to-rem(20);
  margin-bottom: --px-to-rem(12);
}

.primaryButton {
  flex: 1;
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  border-radius: --px-to-rem(8);
  border: 1px solid var(--cloud-3);
  background-color: var(--background-primary);
  padding-block: --px-to-rem(4);

  overflow: hidden;
  box-shadow: var(--shadow-2);

  box-sizing: content-box;

  > * {
    padding-block: --px-to-rem(8);
    padding-inline: --px-to-rem(12);
    display: flex;
    align-items: center;
    gap: --px-to-rem(8);
    background-color: var(--background-primary);

    transition: background-color 0.2s ease;

    color: var(--black-2);

    &:hover {
      outline: none;
      background-color: var(--cloud-0);
    }

    &[data-isselected='true'] {
      background-color: var(--cloud-1);
    }
  }
}

.error-message__container {
  @apply mb-2 block;
}

.error-message {
  @apply lowercase text-red-1;
}

.error-message::first-letter {
  @apply uppercase;
}

.heading {
  @apply text-red-1 mb-1;
}

.container {
  @apply flex justify-between w-full;
}

.header {
  @apply flex items-center text-left w-full;
}

.content {
  @apply ml-3.5 w-full;
}

.heading {
  @apply text-sm text-black-2 mb-1 w-full;
}

.heading--big {
  @apply text-lg font-bold;
}

.chips {
  @apply flex;
}

.icon-container {
  @apply w-8 h-8 flex-none flex items-center;
}

.icon-container > svg,
.icon-container > img {
  @apply max-w-full max-h-full mx-auto w-8 h-8;
}

.header-container {
  @apply flex justify-between items-start w-full;
}

.tooltip {
  @apply !text-primary-1;
}

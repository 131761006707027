.portal {
  transform: translate(-50%, 60px) !important;
  left: 50% !important;
  @apply absolute w-[461px] bg-white p-6 left-0 top-0 rounded-[26px] z-[32];
}

.content {
  @apply text-sm text-gray-1 font-medium;
}

.buttons {
  @apply flex justify-end;
}

.buttons > * {
  @apply mr-2;
}

button.button__delete {
  @apply text-red-1;
}

.header {
  @apply text-red-1;
}

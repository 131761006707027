.container {
  @apply ml-1;
}

button.button__delete svg {
  @apply w-5 h-5;
}

button.button__delete svg path {
  @apply fill-gray-2;
}

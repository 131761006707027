.container {
  @apply flex justify-between items-start;
}

.header {
  @apply flex items-center mb-4 gap-3.5;
}

.header > img,
.header > svg {
  @apply w-12 h-12 shrink-0;
}

.description {
  @apply text-gray-1;
}

.actions {
  @apply flex items-center gap-2.5;
}

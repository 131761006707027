@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.add_button {
  display: flex;
  flex-direction: column;
  width: --px-to-rem(128);
  gap: --px-to-rem(16);
}

@import 'libs/northstar/src/styles/pxToRem';

.hiddenInput {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  gap: --px-to-rem(12);
  cursor: pointer;
}

// RADIO/CHECKBOX
.customInput {
  width: --px-to-rem(20);
  height: --px-to-rem(20);
  min-width: --px-to-rem(20);
  min-height: --px-to-rem(20);
  color: white;
  border: --px-to-rem(1.5) solid;

  // Center icon
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all ease-in-out 0.2s;

  overflow: hidden;

  &--radio {
    border-radius: 9999px; // large enough to be a circle
  }

  &--checkbox {
    border-radius: --px-to-rem(4);
  }

  &--toggle {
    border-radius: 9999px;
    width: --px-to-rem(17);
    height: --px-to-rem(17);
    min-width: --px-to-rem(17);
    min-height: --px-to-rem(17);
  }

  &--default {
    border-color: var(--cloud-3);
    background-color: var(--background-primary);

    &:hover {
      border-color: var(--gray-3);
      background: var(--cloud-2);
    }
  }

  &--holding {
    background-color: var(--primary-1);
    border-color: var(--primary-1);
  }

  &--active {
    background-color: var(--green-1);
    border-color: var(--green-1);
  }

  &--warning {
    background-color: var(--orange-1);
    border-color: var(--orange-1);
  }

  &--error {
    background-color: var(--red-1);
    border-color: var(--red-1);
  }

  &--locked-inactive {
    color: var(--cloud-3);
    border-color: var(--cloud-3);
  }

  &--locked-active {
    background-color: var(--green-1);
    border-color: var(--green-1);
  }
}

// TOGGLE
.toggleWrapper {
  width: --px-to-rem(36);
  height: --px-to-rem(20);
  min-width: --px-to-rem(36);
  min-height: --px-to-rem(20);

  border-radius: 28px;
  padding: --px-to-rem(2);

  display: flex;
  align-items: center;

  transition: all ease-in-out 0.2s;

  &--default {
    background-color: var(--cloud-3);

    &:hover {
      background-color: var(--gray-3);
    }
  }

  &--holding {
    background-color: var(--primary-1);
  }

  &--active {
    background-color: var(--green-1);
  }

  &--warning {
    background-color: var(--orange-1);
  }

  &--error {
    background-color: var(--red-1);
  }

  &--locked-inactive {
    background-color: var(--cloud-3);
  }

  &--locked-active {
    background-color: var(--green-1);
  }

  .customInput {
    position: relative;
    border-radius: 9999px;
    width: --px-to-rem(16);
    height: --px-to-rem(16);
    min-width: --px-to-rem(16);
    min-height: --px-to-rem(16);
    border: none;
    background-color: var(--background-primary); // knob color

    // Center icon
    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform ease-in-out 0.2s 0.15s;

    overflow: hidden;

    &--checked {
      transition: transform ease-in-out 0.2s;
      transform: translateX(--px-to-rem(16));
    }

    &--default {
      color: white;
    }

    &--holding {
      color: var(--primary-1);
    }

    &--active {
      color: var(--green-1);
    }

    &--warning {
      color: var(--orange-1);
    }

    &--error {
      color: var(--red-1);
    }

    &--locked-inactive {
      color: var(--gray-3);
    }

    &--locked-active {
      color: var(--green-1);
    }
  }
}

.sandbox-progress__container {
  @apply flex flex-col justify-center items-center gap-y-1;
}

.sandbox-progress__label {
  @apply !text-xs text-gray-1;
}

.sandbox-progress__status {
  @apply text-[17px] font-medium;
}

.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 px-6 pt-[60px] pb-24;
}

.layout {
  @apply !left-0 !w-full;
}

.alert {
  @apply mb-4 mt-2;
}

.banner {
  @apply mb-4;
}

.footer__buttons {
  @apply flex gap-2 mt-8;
}

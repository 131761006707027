@import 'libs/northstar/src/styles/pxToRem';

.form {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);

  .error {
    color: var(--red-1);
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(30);
  position: relative;
  z-index: 900;
  padding-bottom: --px-to-rem(50);
}

.section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.input_section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.toggle_section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.button_container {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(8);
}

.delete_button {
  color: red;
  width: --px-to-rem(128);
}

.save_button {
  width: --px-to-rem(220);
}

.position {
  width: --px-to-rem(120);
}

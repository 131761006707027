@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  gap: --px-to-rem(12);
}

.header {
  display: flex;
  flex-direction: column;
}

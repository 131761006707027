.container {
  @apply flex items-center justify-between w-full;
}

.token {
  @apply text-gray-1 text-xs overflow-hidden text-ellipsis max-w-[220px];
}

button.button--delete svg {
  @apply w-5 h-5;
}

button.button--delete svg path {
  @apply fill-gray-2;
}

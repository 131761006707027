button.container {
  @apply relative rounded-md border-[1px] py-[7px] px-[12px] text-xs font-bold leading-4 transition-all duration-300 overflow-hidden gap-1.5 flex items-center justify-center;

  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
}

button.container:hover:not(:active):not(:disabled) {
  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%),
    0 8px 12px -3px rgb(24 39 75 / 6%);
}

.leading,
.suffix {
  @apply flex-shrink-0;
}

button.large {
  @apply rounded-lg text-sm leading-[18px] py-3.5 px-5;
}

button.full {
  @apply w-full;
}

button.primary {
  @apply text-white bg-primary-1 border-primary-1;
}

button.primary .spinner-container {
  @apply bg-primary-1;
}

button.primary:hover {
  @apply bg-primary-dark border-primary-dark;
}

button.primary:active {
  @apply bg-primary-2 border-primary-2;
}

button.primary.disabled {
  @apply text-gray-2 bg-cloud-1 border-cloud-1;
}

button.secondary {
  @apply text-black-2 bg-white border-cloud-2;
}

button.secondary .spinner-container {
  @apply bg-white;
}

button.secondary:hover {
  @apply bg-cloud-0 border-cloud-2;
}

button.secondary:active {
  @apply bg-cloud-1 border-cloud-1;
}

button.secondary.disabled {
  @apply text-gray-2 bg-white border-cloud-2;
}

button.secondary:hover:not(:disabled) .leading,
button.secondary:hover:not(:disabled) .suffix {
  @apply text-primary-1;
}

button.secondary .spinner {
  @apply text-primary-1;
}

button.secondary .leading,
button.secondary .suffix {
  @apply text-gray-2;
}

button.link {
  @apply text-primary-1 bg-transparent border-none py-0 px-2;
}

button.link.disabled {
  @apply text-gray-2;
}

button.link:hover {
  @apply !shadow-none;
}

button.link:hover:not(:disabled) .leading,
button.link:hover:not(:disabled) .suffix {
  @apply text-primary-2;
}

button.link .suffix {
  @apply flex items-center w-4 h-4;
}

button.link .spinner {
  @apply text-primary-2;
}

button.delete {
  @apply text-red-1 bg-white border-cloud-2;
}

button.delete .spinner-container {
  @apply bg-white;
}

button.delete:hover {
  @apply bg-cloud-0 border-cloud-2;
}

button.delete:active {
  @apply bg-cloud-1 border-cloud-1;
}

button.delete.disabled {
  @apply text-gray-2 bg-white border-cloud-2;
}

.spinner-container {
  @apply transition-opacity duration-300 absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center;
}

.spinner {
  @apply w-[18px] h-[18px];

  color: #f7f7f9;
}

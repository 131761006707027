@import 'libs/northstar/src/styles/pxToRem';
.portal {
  background-color: var(--background-primary);
  transform: translate(-50%, -50%) !important;
  width: --px-to-rem(400);
  @apply absolute bg-white p-6 left-1/2 top-1/2 rounded-2xl z-40;
  overflow-y: auto;
  max-height: 100vh;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
}
.paragraph {
  @apply text-black-2 text-sm font-medium mt-2;
  text-align: center;
}
button.button--delete {
  @apply text-red-1;
}
button.cancel--button {
  @apply mr-3;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: --px-to-rem(12);
  margin-bottom: --px-to-rem(12);
  text-align: center;
  font-size: --px-to-rem(18);
}

.link {
  color: var(--primary-1);
  cursor: pointer;

  &:hover {
    color: var(--primary-dark);
  }
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  gap: --px-to-rem(8);
  margin-top: --px-to-rem(12);
  margin-bottom: --px-to-rem(4);
  font-size: --px-to-rem(15);
}

.sectionText {
  margin: 0;
}
.sectionParagraph {
  margin-left: --px-to-rem(32);
  font-size: --px-to-rem(15);
}
.list {
  margin-left: --px-to-rem(32);
  display: list-item;
  list-style-type: circle;
}

.acknowledgement {
  display: flex;
  background-color: #ffffff;
  align-items: center;
  gap: --px-to-rem(8);
  padding: --px-to-rem(8);
  border: 1px solid #e5e7eb;
  border-radius: --px-to-rem(8);
  margin: --px-to-rem(24) --px-to-rem(4) 0;

  input[type='checkbox'] {
    margin: 0;
  }
}

.acknowledgementText {
  margin: 0;
  font-size: --px-to-rem(12);
}
.buttonWrapper {
  display: flex;
  justify-content: center;
}
.button {
  margin-top: --px-to-rem(16);
  width: 100%;
}

.upgradeGuideLink {
  @extend .link;
  font-size: --px-to-rem(12);
}

.container {
  @apply flex justify-between items-center mt-3;
}

.results {
  @apply text-xs text-black-2 font-medium;
}

.pagination {
  @apply flex items-center h-5;
}

.pagination__page {
  @apply mx-1 h-5 w-5 rounded flex items-center justify-center;
}

.pagination__page--active {
  @apply bg-cloud-0;
}

.pagination__link {
  @apply text-xs text-gray-2;
}

.pagination__link--active {
  @apply text-xs text-black-2;
}

.pagination__prev {
  @apply h-5 w-5 mr-2 flex items-center justify-center;
}

.pagination__next {
  @apply h-5 w-5 ml-2 flex items-center justify-center;
}

.pagination__next.disabled {
  @apply opacity-50 pointer-events-none;
}

.disabled {
  @apply opacity-50 pointer-events-none;
}

.pagination__break {
  @apply h-5 w-5 flex items-center justify-center;
}

.pagination__break_link {
  @apply text-xs text-gray-2;
}

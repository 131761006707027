.container {
  @apply h-screen w-screen flex flex-col items-center;
}

.header {
  @apply flex-1 md:flex-none flex justify-around items-center w-full pt-4;
}

.logo {
  @apply justify-self-center;
}

.main {
  @apply flex-auto md:flex-none flex flex-col items-center w-full mb-0 mt-auto md:m-auto max-w-[698px] md:px-0 px-6 mt-32 md:mt-0;
}

.heading {
  @apply md:text-[56px] md:leading-[64px] text-[28px] leading-[36px] text-black-2 text-center mb-3 font-semibold;
}

.subheading {
  @apply text-[48px] leading-[56px] text-primary-1 text-center mb-3 font-semibold;
}

.paragraph {
  @apply text-gray-1 text-center mb-8 text-[16px] leading-[24px];
}

.footer {
  @apply w-full flex-1 md:flex-none flex flex-col md:flex-row justify-center items-center border-t-[1px] gap-[24px] text-gray-1 py-6 md:py-4 text-[14px];
}

.footerLink {
  @apply !text-[14px];
}

.onboardingIcon {
  @apply md:w-full md:h-[340px];
}

.checkIconContainer {
  @apply w-[80px] h-[80px] rounded-full bg-[#F2F6FF] flex items-center justify-center;
}

.checkIcon {
  @apply text-primary-1;
}

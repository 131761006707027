.rule-view {
  @apply flex flex-col gap-2 my-4;
}

.rule-view--row {
  @apply flex justify-between items-center gap-4;
}

.rule-view--row-field {
  @apply flex-1 mb-0;
}

/* Remove arrows/spinners from input type number */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

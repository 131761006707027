@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  position: relative;
  width: 100%;

  .input {
    border-radius: --px-to-rem(8);
    outline: none;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    border: 1px solid var(--cloud-3);
    background: var(--cloud-0);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    color: var(--black-2);
    width: 100%;

    transition: all 0.1s ease-in-out;

    &--padding-small {
      padding: --px-to-rem(6) --px-to-rem(10);
    }

    &--padding-regular {
      padding: --px-to-rem(13) --px-to-rem(15);
    }

    &--with-label {
      padding-top: --px-to-rem(19);
      padding-bottom: --px-to-rem(7);
    }

    // Error needs to have higher specificity
    &--error {
      border: 1px solid var(--red-1) !important;
      background-color: var(--red-3) !important;
      color: var(--red-1) !important;
    }

    &:focus {
      background: var(--background-primary);
      border: 1px solid var(--gray-2);
    }

    &:not(:placeholder-shown) {
      background: var(--background-primary);
    }

    &:not(:focus):hover {
      background: var(--cloud-1);
    }

    &:disabled {
      border: 1px solid var(--cloud-3);
      opacity: 0.5;
      background: var(--cloud-1);
      color: var(--gray-2);
      cursor: not-allowed;
    }

    &[readonly] {
      border: 1px solid var(--cloud-3);
      background: var(--cloud-0);
      color: var(--gray-1);
      font-weight: 400;

      // Disable hover effect
      &:hover {
        background: var(--cloud-0);
      }
    }

    // hide arrows in number input CHROME
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // hide arrows in number input FIREFOX
    [type='number'] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
    }

    // Required for proper styling of label
    &::placeholder {
      color: transparent;
      visibility: hidden;
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;

    color: var(--black-2);
    transform: translate(--px-to-rem(16), --px-to-rem(13));

    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;

    transition: all 0.1s ease-in-out;

    // Error needs to have higher specificity
    &--error {
      color: var(--red-1) !important;
    }

    &:hover {
      color: var(--gray-1);
    }

    &:disabled {
      color: var(--gray-2);
    }
  }

  .input:placeholder-shown ~ .label {
    color: var(--gray-2);

    .placeholder__text {
      display: block;
    }

    .label__text {
      display: none;
    }
  }

  .input:disabled ~ .label {
    color: var(--gray-2);
    cursor: not-allowed;
  }

  // Move label up when input is focused or there is a value
  .input:focus ~ .label,
  .input:not(:placeholder-shown) ~ .label {
    transform: translate(--px-to-rem(16), --px-to-rem(7));

    color: var(--gray-1);

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    &--padding-small {
      transform: translate(--px-to-rem(11), --px-to-rem(7));
    }

    .placeholder__text {
      display: none;
    }

    .label__text {
      display: block;
    }
  }

  .right {
    position: absolute;
    right: 0;
    top: 50%;

    transform: translate(--px-to-rem(-16), -50%);

    display: flex;
    gap: --px-to-rem(6);
    justify-content: flex-end;

    color: var(--gray-2);

    &--error {
      color: var(--red-1);
      transform: translate(--px-to-rem(-16), -100%);
    }
  }

  .input:disabled ~ .right {
    cursor: not-allowed;
  }

  .errorText {
    color: var(--red-1);

    &--padding-small {
      padding: --px-to-rem(2) --px-to-rem(10);
    }

    &--padding-regular {
      padding: --px-to-rem(2) --px-to-rem(15);
    }
  }
}

.error-message {
  @apply lowercase;
}

.error-message::first-letter {
  @apply uppercase;
}

.heading {
  @apply font-bold;
}

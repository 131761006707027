.container {
  @apply grid mb-6;
}

.headers {
  @apply grid gap-2 mb-2;
}

.header {
  @apply text-gray-1;
}

.container {
  @apply relative h-[46px] text-black-2 text-sm;
}

input.input {
  @apply absolute h-[46px] w-full border !border-cloud-1 !outline-cloud-1 pl-3 pr-[30px] rounded-lg block;
}

.color-preview {
  @apply w-4 h-4 bg-white absolute right-3 top-1/2 transform -translate-y-1/2 border border-cloud-2 rounded-sm transition-colors duration-300;
}

.container {
  @apply flex-col items-start w-full;
}

.wave {
  @apply w-max;
}

.button__logout {
  @apply w-full mt-4;
}

.logout {
  @apply w-4 h-4 cursor-pointer;
}

.logout path {
  @apply fill-gray-2;
}

.container {
  @apply p-4 mb-4 rounded-2xl bg-primary-4;
}

.heading {
  @apply text-primary-1;
}

.paragraph {
  @apply text-sm text-gray-1;
}

.subtitle {
  @apply text-xs text-gray-1;
}

.action__container {
  @apply flex mt-3;
}

.button__accept {
  @apply mr-2;
}

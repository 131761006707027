.container {
  @apply bg-cloud-0 max-w-[355px] h-fit p-4 border border-cloud-1 rounded-xl;
}

.header {
  @apply flex items-center mb-2;
}

.heading {
  @apply ml-2.5;
}

.header svg path {
  @apply fill-primary-1;
}

.content {
  @apply !text-gray-1 mb-3;
}

.documentation__container {
  @apply flex items-center bg-white w-max py-[9px] px-[14px] rounded-md border border-cloud-2;

  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
}

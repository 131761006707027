@import 'libs/northstar/src/styles/pxToRem';

.action-icon {
  color: var(--toggle-row-action-color);
}

.drawer {
  max-width: 580px;

  .container {
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(24);

    .section {
      display: flex;
      flex-direction: column;
      gap: --px-to-rem(8);

      .link {
        text-decoration-line: underline;
      }

      .form {
        padding: --px-to-rem(16);
        background: var(--cloud-0);

        .error {
          color: var(--red-1);
        }
      }
    }
  }
}

.control {
  @apply w-36 h-8 px-3.5 space-x-2 border border-cloud-2 flex items-center justify-center text-xs text-gray-2;

  border-radius: 44px;
  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
}

.item {
  @apply flex items-center h-8 pl-4 pr-1.5 cursor-pointer text-xs text-black-2 font-medium;
}

.control__selected {
  @apply grow justify-end text-left;
}

.control__arrow {
  @apply flex-none justify-end;
}

.item:hover {
  @apply bg-cloud-0;
}

.item__text {
  @apply lowercase;
}

.item__text::first-letter {
  @apply capitalize;
}

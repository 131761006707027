@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.upgradeBanner {
  margin-bottom: --px-to-rem(16);
}

.showMfaSection {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--cloud-3);
  border-radius: 1rem;
  padding: --px-to-rem(8) --px-to-rem(24);
  align-items: center;
}

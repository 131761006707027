.upsell-banner__container {
  @apply flex justify-between items-center p-2 transition transition-opacity transition-[position] duration-300;
}

.upsell-banner__container--closed {
  @apply opacity-0 absolute;
}

.upsell-banner--label.warning p {
  @apply !text-yellow-1;
}

.upsell-banner__actions-container {
  @apply flex items-center justify-end cursor-pointer;
}

.upsell-banner__actions-container .upsell-banner--link {
  @apply text-primary-2 font-bold text-sm shadow-none;
}

.upsell-banner__actions-container .upsell-banner--link-icon {
  @apply -rotate-90 w-[10px];
}

.upsell-banner__actions-container .upsell-banner--link-icon path {
  @apply fill-primary-2;
}

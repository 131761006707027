@import 'libs/northstar/src/styles/pxToRem';

.opacity {
  position: fixed;
  z-index: 1;
}

.container {
  width: 100vw;
  top: 57px;
  left: 0;
  position: fixed;
  z-index: 1;

  background: var(--primary-4);

  display: flex;
  justify-content: center;

  .wrapper {
    max-width: var(--content-max-width);
    padding: 10px 62.5px 10px 74px;
    width: 100%;

    display: flex;
    align-items: center;

    gap: --px-to-rem(8);

    .cancel {
      margin-left: auto;
    }
  }
}

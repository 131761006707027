.portal {
  transform: translate(-50%, 60px) !important;
  left: 50% !important;
  overflow: visible !important;

  @apply absolute bg-white p-6 left-0 top-0 rounded-[26px];
}

.buttons {
  @apply flex justify-end gap-2;
}

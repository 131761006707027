.container {
  @apply flex p-3 items-center font-bold text-black-2 text-xs fixed bottom-10 left-1/2 rounded-lg bg-white gap-3 z-10;

  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%),
    0 8px 12px -3px rgb(24 39 75 / 6%);
}

.position-absolute {
  @apply absolute bottom-auto top-0;
}

.default {
  @apply bg-primary-4 text-primary-1;
}

.default svg path {
  @apply fill-primary-1;
}

.success {
  @apply bg-green-4 text-green-1;
}

.success svg path {
  @apply fill-green-1;
}

.error {
  @apply bg-red-4 text-red-1;
}

.error svg path {
  @apply fill-red-1;
}

.warning {
  @apply bg-yellow-4 text-yellow-1;
}

.warning svg path {
  @apply fill-yellow-1;
}

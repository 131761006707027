@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  background: var(--cloud-0);
  min-width: 100%;
  width: 100%;
  min-height: 30px;
  border-radius: 4px;

  display: flex;
  flex: 1;

  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: var(--skeleton-loading-gradient);
    animation: shimmer 2s infinite;
    content: '';
  }
}

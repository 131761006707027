.container {
  @apply flex max-h-screen w-full;
}

.logo {
  @apply absolute left-8 top-8;
}

.change__wallet {
  @apply absolute top-8 right-12;
}

.section__left {
  -ms-overflow-style: none;
  scrollbar-width: none;

  @apply bg-white w-[60%] flex items-center justify-center relative overflow-y-scroll overflow-x-hidden py-4;
}

.section__left::-webkit-scrollbar {
  display: none;
}

.section__right {
  @apply w-[40%] h-screen bg-no-repeat bg-cover;
}

.network__expand {
  margin-left: auto;
}

button.button__expand {
  @apply border border-white border-solid;

  margin-left: auto;
}

button.button__expand svg {
  @apply w-7 h-7;
}

button.button__expand svg path {
  @apply fill-gray-2;
}

button.button__expanded {
  @apply bg-cloud-0 border-cloud-1 border border-solid;

  border-radius: 6px;
}

.button__expanded svg {
  transform: rotate(180deg);
}

button.button__expanded svg path {
  @apply fill-primary-1;
}

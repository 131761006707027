.chart-summary__item {
  @apply flex justify-between items-center !border-0 mb-2;
}

.chart-summary__copy {
  @apply flex items-center gap-2 justify-between;
}

.chart-summary__item:last-child {
  @apply mb-0;
}

.chart-summary__bullet {
  @apply rounded-full w-2 h-2;
}

.chart-summary__bullet--visitors {
  @apply bg-primary-1;
}

.chart-summary__bullet--unique {
  @apply bg-primary-dark-2;
}

.chart-summary__label,
.chart-summary__value {
  @apply text-sm;
}

.chart-summary__item--disabled .chart-summary__bullet {
  @apply bg-cloud-2;
}

.chart-summary__item--disabled .chart-summary__label,
.chart-summary__item--disabled .chart-summary__value {
  @apply text-gray-2;
}

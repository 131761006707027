@import 'libs/northstar/src/styles/pxToRem';
.tag {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: --px-to-rem(30);
  gap: --px-to-rem(4);
  align-items: center;
  width: max-content;

  &--medium {
    padding: --px-to-rem(3) --px-to-rem(6);
    height: --px-to-rem(20);
  }

  &--large {
    padding: --px-to-rem(3) --px-to-rem(8);
    height: --px-to-rem(24);
  }

  &__text-large {
    line-height: --px-to-rem(16);
  }

  &--icon-left {
    flex-direction: row-reverse;
  }

  &--gray {
    background-color: var(--cloud-3);
    color: var(--gray-1);
  }

  &--warning {
    background-color: var(--orange-1);
    color: var(--white);
  }

  &--transparent {
    border: 1px solid var(--cloud-3);
    background-color: transparent;
    color: var(--gray-1);
  }

  &--primary {
    background-color: var(--primary-3);
    color: var(--primary-1);
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.form {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.link {
  color: #0000ff; /* Basic blue color */
  text-decoration: underline;

  &:hover {
    color: #0000cc; /* Darker blue on hover */
  }
}

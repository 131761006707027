.search-container {
  @apply relative;
}

.search {
  @apply text-xs h-8 w-[283px] block border !border-cloud-2 !outline-cloud-2 pl-8;

  box-shadow: 0 1px 2px rgb(24 39 75 / 4%);
  border-radius: 44px 0 0 44px;
}

.search::placeholder {
  @apply text-xs text-gray-2;
}

.icon {
  @apply absolute top-1/2 left-2.5 transform -translate-y-1/2 text-gray-2;
}

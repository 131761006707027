@import 'libs/northstar/src/styles/pxToRem';

.heading_container {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: --px-to-rem(8);
  margin-bottom: --px-to-rem(8);
}

.description {
  font-size: --px-to-rem(14);
  line-height: --px-to-rem(20);
  margin-bottom: --px-to-rem(12);
}

.tag {
  text-transform: uppercase;
  margin-bottom: --px-to-rem(8);
}

.input {
  margin-bottom: --px-to-rem(24);
  background-color: white;
}

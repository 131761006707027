.header {
  @apply flex items-center gap-2 whitespace-nowrap;
}

.header__title {
  @apply text-sm;
}

.terms {
  @apply pb-4;
}

.terms__alert {
  @apply my-2 gap-3 flex flex-col;
}

.terms__alert--bold {
  @apply font-bold;
}

.terms__signed {
  @apply inline-flex items-center gap-1;
}

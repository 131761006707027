button.button__expand {
  @apply py-4 w-full flex justify-between items-center gap-2 cursor-default;
}

button.button__expand--no-padding {
  @apply py-0;
}

.button__expand--enabled {
  @apply !cursor-pointer;
}

.icon {
  @apply w-7 h-7 transition-all duration-300 border border-transparent rounded-[6px] flex items-center justify-center ml-auto;
}

button.button__expand:hover .icon,
.icon__expanded {
  @apply bg-cloud-0 border-cloud-1 border border-solid rounded-[6px];
}

.icon svg {
  @apply w-7 h-7 transition-all duration-300;
}

.icon svg path {
  @apply fill-gray-2;
}

.icon__expanded svg {
  transform: rotate(180deg);
}

.toggle {
  @apply flex-none m-0;
}

.toggle--top {
  @apply mb-auto;
}

.wrapper {
  @apply w-full flex gap-2 items-center justify-start;
}

.text {
  @apply flex flex-col gap-1;
}

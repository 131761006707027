button.container {
  @apply bg-primary-1 text-white text-xs rounded-lg py-2 px-3;
}

button.active {
  @apply bg-white text-primary-1;
}

.wrapper {
  @apply relative flex items-center justify-center transition ease-in-out delay-150;
}

.is-animating svg {
  @apply scale-[80%];
}

.tooltip {
  @apply absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full odd:bg-black-2 w-max rounded px-2 py-1 text-white font-medium text-xs;
}

.tooltip::after {
  content: ' ';
  @apply absolute -bottom-1 left-1/2 transform -translate-x-1/2 -translate-y-0.5 -rotate-45  origin-top-right bg-black-2  h-2 w-2;
}

.icon--primary path {
  @apply fill-primary-1;
}

.icon--gray path {
  @apply fill-gray-1;
}

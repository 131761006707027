@import 'libs/northstar/src/styles/pxToRem';

.content {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(8);
  padding: --px-to-rem(16);

  .input {
    flex: 1;
  }
}

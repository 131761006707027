.container {
  @apply p-2 grid text-gray-2 border border-cloud-1 rounded-3xl text-sm h-[50px] w-full font-medium;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.option {
  @apply transition-colors duration-300 rounded-3xl text-center h-[34px];
}

.containerDisabled {
  @apply bg-cloud-1 p-2 grid text-gray-2 border border-cloud-1 rounded-3xl text-sm h-[50px] w-full font-medium;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.option--active {
  @apply !bg-cloud-0 text-primary-1;
}

.container {
  @apply relative;
}

.container:not(.no-border) {
  @apply border rounded-2xl p-4;
}

.header__container {
  @apply flex justify-between gap-3 items-center content-center;
}

.header__container button {
  @apply justify-normal;
}

.header__toggle {
  @apply flex-none m-0;
}

.header__title {
  @apply text-sm font-bold text-black-2;

  line-height: normal;
}

.header__chips {
  @apply flex gap-2 mx-2;
}

.inner_container {
  @apply pt-4;
}

.footer__container {
  @apply flex justify-end gap-2;
}

.divider {
  @apply border-b border-gray-300 my-4;

  border-color: #e9ecf2;
}

button.button__expand {
  @apply border border-white border-solid;

  margin-left: auto;
}

button.button__expand svg {
  @apply w-7 h-7;
}

button.button--delete {
  @apply text-red-1;
}

.description__container {
  @apply pb-2;
}

.description {
  @apply text-sm text-gray-1;
}

.content {
  @apply text-sm pt-2;
}

button.button__expanded {
  @apply bg-cloud-0 border-cloud-1 border border-solid;

  border-radius: 6px;
}

.button__expanded svg {
  transform: rotate(180deg);
}

.button__expanded svg path {
  @apply fill-primary-1;
}

.copy_button svg {
  width: 20px;
  height: 20px;
}

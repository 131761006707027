@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: --px-to-rem(10);

  &--rounded {
    border-radius: --px-to-rem(8);
  }

  &--info {
    background-color: var(--cloud-1);
  }

  &--warning {
    background-color: var(--orange-3);
  }

  &--error {
    background-color: var(--red-3);
  }

  &--success {
    background-color: var(--green-3);
  }

  &--advance {
    background-color: var(--primary-4);
  }
}

.container {
  max-width: --px-to-rem(1460);
  display: flex;
  width: 100%;
}

.textFrame {
  display: flex;
  gap: --px-to-rem(8);
  margin-right: auto;
  align-items: center;
}

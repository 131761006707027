@import 'libs/northstar/src/styles/pxToRem';

.action-icon {
  color: var(--toggle-row-action-color);
}

.modal {
  max-width: 600px;

  .drawer {
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(24);

    .section {
      display: flex;
      flex-direction: column;
      gap: --px-to-rem(12);
    }
  }
}

.container {
  @apply flex p-4 items-center border border-cloud-2 text-black-2 text-sm fixed bottom-5;
  @apply left-1/2 rounded-xl bg-white z-30;

  box-shadow: 0 8px 12px -6px rgb(24 39 75 / 6%),
    0 8px 12px -3px rgb(24 39 75 / 6%);
}

.message {
  @apply mr-4;
}

.icon {
  @apply mr-3;
}

.cancel {
  @apply mr-3;
}

.widget__wrapper {
  @apply !min-h-fit !h-fit;
}

.widget-children {
  @apply py-4 gap-4 flex flex-col h-full;
}

.wallet-logo {
  @apply h-4 w-4;
}

.pie-chart-container {
  @apply w-full h-56 flex justify-center items-center grow;
}

.pie-chart {
  @apply max-h-56;
}

.empty-container {
  @apply text-gray-2 text-sm;
}

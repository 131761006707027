@import 'libs/northstar/src/styles/pxToRem';

.card_content {
  padding: --px-to-rem(16) --px-to-rem(16);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
}

.copy {
  background-color: var(--cloud-1);
  padding: --px-to-rem(8) --px-to-rem(12);
  border-radius: --px-to-rem(8);
  display: flex;
  justify-content: space-between;
}

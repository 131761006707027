.chip {
  @apply flex items-center bg-cloud-1 px-2 py-0.5 rounded-xl max-w-fit whitespace-nowrap;
}

.small {
  @apply px-1.5 py-0.5;
}

.chip__text {
  @apply text-gray-1;
}

.chip--white {
  @apply bg-white border;
}

.chip--green {
  @apply bg-green-4;
}

.chip__text--green {
  @apply text-green-1;
}

.chip--blue {
  @apply bg-primary-3;
}

.chip__text--blue {
  @apply text-primary-1;
}

.chip--yellow {
  @apply bg-yellow-4;
}

.chip__text--yellow {
  @apply text-yellow-1;
}

.chip--icon-lock {
  @apply w-3 h-3 mr-1 text-yellow-1;
}

@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(32);
}

.settings {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(32);
}

.content {
  padding: --px-to-rem(16) --px-to-rem(16);
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);

  &__row {
    display: flex;
    flex-direction: row;
    gap: --px-to-rem(16);

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }
}

.container {
  @apply max-w-[350px] p-4 border border-cloud-2 rounded-xl flex flex-col justify-start items-start;
}

.container--coming-soon {
  @apply cursor-default;
}

.content {
  @apply text-gray-1 mt-4;
}

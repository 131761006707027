.container {
  @apply relative;
}

.container:not(.no-border) {
  @apply border rounded-2xl p-4;
}

.header__container {
  @apply flex justify-between gap-3 items-center content-center;
}

.header__container button {
  @apply justify-normal;
}

.header__toggle {
  @apply flex-none m-0;
}

.header__title {
  @apply text-sm font-bold text-black-2 leading-normal;
}

.header__chips {
  @apply flex gap-2 mx-2;
}

.inner_container {
  @apply pt-4;
}

.footer__container {
  @apply flex justify-end gap-2;
}

button.button__expand {
  @apply border border-white border-solid ml-auto;
}

button.button__expand svg {
  @apply w-7 h-7;
}

button.button--delete {
  @apply text-red-1;
}

.description__container {
  @apply pb-2;
}

.description {
  @apply text-sm text-gray-1;
}

.content {
  @apply text-sm pt-2;
}

button.button__expanded {
  @apply bg-cloud-0 border-cloud-1 border border-solid rounded-md;
}

.button__expanded svg {
  @apply rotate-180;
}

.button__expanded svg path {
  @apply fill-primary-1;
}

@import 'libs/northstar/src/styles/pxToRem';

.section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
}

.button {
  margin-top: --px-to-rem(16);
}

.heading {
  @apply mb-1;
}

.wrapper {
  @apply flex justify-between items-end;
}

.paper {
  @apply relative;
}

.container {
  @apply w-full;
}

.container-with-image {
  width: calc(100% - 262px);
}

.image {
  @apply w-[262px] h-full rounded-lg;
  @apply absolute bg-cover bg-no-repeat right-0.5 bottom-0 bg-bottom;
}

.overview-card__content--blue {
  @apply bg-primary-4 border-0;
}

.overview-card__content--blue a {
  @apply text-primary-1;
}

.container {
  @apply flex flex-col gap-1 px-4 justify-center w-full;

  min-height: 90px;
}

.title {
  @apply text-base text-black-2 font-medium;
}

.value {
  @apply font-bold;

  font-size: 28px;
  min-height: 42px;
}

.container--disabled {
  @apply select-none;
}

.container--disabled .title {
  @apply text-gray-1;
}

.container--disabled .value {
  @apply text-cloud-2;
}

@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: --px-to-rem(24);
}

.content {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(24);
}
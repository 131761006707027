@import 'libs/northstar/src/styles/pxToRem';

.card {
  display: flex;
  flex-direction: column;
  border-radius: --px-to-rem(8);
}

.card--default {
  background-color: transparent;
  border: 1px solid var(--cloud-3);
}

.card--grey {
  background-color: var(--cloud-1);
  border: 1px solid var(--cloud-3);
}

.card--error {
  background-color: var(--red-3);
}

/** Header styles */
.header {
  display: flex;
  flex-direction: column;
  padding: --px-to-rem(16);
}

.header--default {
  background-color: transparent;
}

.header--grey {
  background-color: var(--cloud-1);
}

/** Content styles */
.content {
  display: flex;
  flex-direction: column;
  padding: --px-to-rem(16);
}

.content--default {
  background-color: transparent;
}

.content--grey {
  background-color: var(--cloud-1);
}

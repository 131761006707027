.summary__wrapper {
  @apply py-4;
}

.widget-children {
  @apply pb-4 gap-4 flex flex-col h-full !border-0;
}

.chart-container {
  @apply relative grow;
}

.empty-container {
  @apply absolute text-gray-2 text-sm bg-white p-1 top-2/4 right-2/4;

  transform: translate(50%, -50%);
}

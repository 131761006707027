@import 'libs/northstar/src/styles/pxToRem';

.dialog {
  max-width: --px-to-rem(430);

  .content {
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(24);
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: --px-to-rem(12);

    button {
      flex: 1;
    }
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  width: --px-to-rem(12);
  height: --px-to-rem(12);

  display: flex;
  justify-content: center;
  align-items: center;

  .backdrop {
    padding: --px-to-rem(2);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    .dot {
      width: --px-to-rem(8);
      height: --px-to-rem(8);
      border-radius: 50%;
    }
  }

  &--live {
    .backdrop {
      background-color: var(--green-3);

      .dot {
        background-color: var(--green-1);
      }
    }
  }

  &--sandbox {
    .backdrop {
      background-color: var(--orange-3);

      .dot {
        background-color: var(--orange-1);
      }
    }
  }
}

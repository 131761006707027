.card {
  @apply border border-cloud-2 rounded-xl p-4 mb-3 transition-all duration-300;

  box-shadow: 0 1px 2px 0 #18274b0a;
}

.card:hover {
  box-shadow: 0 8px 12px -6px #18274b0f;
}

.card:last-child {
  @apply mb-0;
}

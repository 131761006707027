.table__header {
  @apply col-span-3;
}

.heading {
  @apply text-xs text-gray-2;
}

.row__container {
  @apply grid grid-cols-5 gap-2 p-1 items-center text-sm;
}

.row__session {
  @apply border-t border-cloud-2;
}

.errorHeading {
  @apply text-red-1 mb-1;
}

.errorMessage {
  @apply text-red-1;
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  max-width: --px-to-rem(200);
  width: --px-to-rem(200);
}

.skeleton {
  width: --px-to-rem(200);
}

.item {
  padding: --px-to-rem(8) --px-to-rem(12);
  display: flex;
  align-items: center;
  background-color: var(--background-primary);

  transition: background-color 0.2s ease;

  .trigger {
    visibility: hidden;
    color: var(--gray-2);

    transition: color 0.2s ease;
  }

  &:hover,
  &[data-issubmenuopen='true'] {
    outline: none;
    background-color: var(--cloud-0);

    .trigger {
      visibility: visible;

      color: var(--gray-1);
    }
  }

  &[data-isselected='true'] {
    background-color: var(--cloud-1);

    .trigger {
      visibility: visible;

      color: var(--gray-1);
    }
  }

  .name {
    margin-left: --px-to-rem(8);
    margin-right: --px-to-rem(6);

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tag {
    text-transform: uppercase;
  }
}

.subItem {
  padding: --px-to-rem(8) --px-to-rem(12);
  background-color: var(--background-primary);

  &:hover {
    outline: none;
    background-color: var(--cloud-0);
  }
}

@import 'libs/northstar/src/styles/pxToRem';

.container {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(24);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

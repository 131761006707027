:root {
  --white: #ffffff;
  --cloud-0: rgba(85, 90, 124, 0.03);
  --cloud-1: #f5f5f7;
  --cloud-2: #eef0f4;
  --cloud-3: #e9ecf2;
  --black-1: #26272e;
  --black-2: #3b3d47;
  --gray-1: #5f6478;
  --gray-2: #8f93ab;
  --gray-3: #daddea;
  --primary-1: #4779ff;
  --primary-2: #5c89ff;
  --primary-3: #ecf1ff;
  --primary-4: #f5f8ff;
  --primary-dark: #2963ff;
  --green-1: #09916b;
  --green-3: #e9fbf2;
  --green-dark: #065f46;
  --orange-1: #f97700;
  --orange-3: #fdf3e9;
  --orange-dark: #d06400;
  --orange-4: rgba(255, 159, 71, 0.1);
  --yellow-1: #92400e;
  --yellow-3: #fffae4;
  --red-1: #991b1b;
  --red-2: #e61c1c;
  --red-3: #ffefef;

  --background-primary: #ffffff;

  // Shadows
  --shadow-1: 0px 1px 2px 0px rgba(24, 39, 75, 0.04);
  --shadow-2: 0px 4px 8px -6px rgba(24, 39, 75, 0.02),
    0px 8px 24px -4px rgba(24, 39, 75, 0.05);
  --shadow-3: 0px 8px 12px -6px rgba(24, 39, 75, 0.06),
    0px 8px 12px -3px rgba(24, 39, 75, 0.06);

  // const Heights
  --organizations-dropdown-list-height: 200px;

  // const Widths
  --content-max-width: 1920px;
  --page-inline-padding-left: 52px;
  --page-inline-padding-right: 36px;

  --skeleton-loading-gradient: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    rgb(255 255 255 / 20%) 20%,
    rgb(255 255 255 / 50%) 60%,
    rgb(255 255 255 / 0%)
  );
}

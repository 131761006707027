@import 'libs/northstar/src/styles/pxToRem';

.content {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(16);
}

.footer {
  display: flex;
  flex-direction: row;
  gap: --px-to-rem(16);
  justify-content: space-around;

  button {
    flex: 1;
  }
}

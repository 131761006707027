.container {
  @apply flex w-full;
}

.options__container {
  @apply border-r border-cloud-1;

  width: 100%;
}

.views__container {
  @apply flex justify-end w-full h-full;

  width: 100%;
}

@import 'libs/northstar/src/styles/pxToRem';

.side-drawer {
  max-width: 600px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);
}

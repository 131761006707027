@import 'libs/northstar/src/styles/pxToRem';

.trigger {
  width: 100%;

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: --px-to-rem(8);

    padding: --px-to-rem(12) --px-to-rem(8);
    border-radius: --px-to-rem(6);
    background-color: var(--background-primary);

    &--sub-item {
      padding-block: --px-to-rem(7);
    }

    transition: background-color 0.2s ease-in-out;

    .icon {
      color: var(--gray-2);
      transition: color 0.2s ease-in-out;
    }

    .text {
      color: var(--gray-1);
      transition: color 0.2s ease-in-out;

      &--no-icon {
        padding-left: --px-to-rem(28);
      }
    }

    .dropdownWrapper {
      margin-left: auto;

      .dropdownIcon {
        margin-left: auto;
        transition: transform 0.3s ease-in-out, color 0.2s ease-in-out;
        color: var(--gray-1);
      }
    }

    // on hover when inactive
    &:not(.container--active):hover {
      background-color: var(--cloud-0);

      .icon {
        color: var(--gray-1);
      }
    }

    &--active {
      background-color: var(--cloud-1);

      .icon,
      .text {
        color: var(--black-2);
      }

      .dropdownWrapper {
        .dropdownIcon {
          color: var(--black-2);
        }
      }
    }
  }

  &[data-state='closed'] .dropdownIcon {
    transform: rotate(0deg);
  }

  &[data-state='open'] .dropdownIcon {
    transform: rotate(-180deg);
  }
}

.tabTrigger {
  @apply px-4 py-3 text-sm text-gray-1 font-bold radix-state-active:text-primary-1 radix-state-active:border-b-2 radix-state-active:border-primary-1;
}

button.button--add svg {
  @apply w-4 h-4;
}

button.button--add svg path {
  @apply fill-gray-2;
}

.container {
  @apply text-xs font-medium p-3 rounded-lg;
}

.warning {
  @apply bg-yellow-4 text-yellow-1;
}

.info {
  @apply bg-primary-4 text-black-2;
}

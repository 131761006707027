.container {
  @apply flex justify-start flex-col flex-1 gap-6;
}

.widgets-row {
  @apply flex w-full items-stretch border rounded-xl;
}

.widgets-grid {
  @apply gap-6;

  display: grid;
  grid-template-columns: auto 27rem;
}

@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(8);
}

.item {
  display: flex;
  align-items: center;
  gap: --px-to-rem(8);
}

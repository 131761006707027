.closable-alert__container {
  @apply flex justify-between p-4 transition transition-opacity transition-[position] duration-300;
}

.closable-alert__container--closed {
  @apply opacity-0 absolute;
}

.closable-alert__container > div {
  @apply flex gap-2;
}

.closable-alert__copy {
  @apply flex flex-col;
}

.closable-alert__learn-more {
  @apply flex gap-1;
}

.closable-alert__learn-more a {
  @apply text-primary-1 font-medium;
}

.closable-alert__btn-close {
  @apply !bg-transparent !border-0 !shadow-none w-fit h-fit !p-0;
}

.closable-alert__btn-close svg path {
  @apply fill-gray-2;
}

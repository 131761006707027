@import 'libs/northstar/src/styles/pxToRem';

.section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(12);
}

.action-icon {
  color: var(--dd-color-text-secondary);
  margin-left: var(--dd-spacing-3);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--dd-spacing-6);
  padding: var(--dd-spacing-8);
}

.side-drawer {
  max-width: 600px;
}

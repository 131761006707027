@import 'libs/northstar/src/styles/pxToRem';

.section {
  display: flex;
  flex-direction: column;
  gap: --px-to-rem(4);

  .title {
    padding: --px-to-rem(8) --px-to-rem(8) --px-to-rem(4) --px-to-rem(8);
    text-transform: uppercase;
  }

  .contentWrapper {
    padding-top: --px-to-rem(4);
    display: flex;
    flex-direction: column;
    gap: --px-to-rem(2);
  }
}

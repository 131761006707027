.submit__container {
  @apply flex justify-end;
}

button.button--add {
  @apply static;
}

button.button--cancel {
  @apply mr-3;
}

button.button--add svg {
  @apply w-4 h-4;
}

button.button--add svg path {
  fill: #949ab2;
}

.add__text {
  @apply text-sm text-black-2;
}

.input-hidden {
  @apply hidden;
}

.input-wrapper {
  display: flex;
  gap: 12px;
}

.custom-input {
  @apply mt-0.5 border-2 border-solid border-cloud-2 min-w-[18px] w-[18px] h-[18px] rounded-full cursor-pointer flex justify-center p-[2px];
}

.input-hidden:disabled + .custom-input {
  @apply cursor-not-allowed p-0;
}

.custom-input__dot {
  @apply flex flex-1 rounded-full transition-all duration-300 ease-in-out;
}

.custom-input__lock {
  @apply w-full bg-cloud-2 flex-1 h-full relative rounded-full;
}

.custom-input__lock svg {
  @apply w-full h-full absolute top-0 left-0 p-0.5;
}

.custom-input__lock svg path {
  @apply fill-gray-2;
}

.input-hidden:checked + .custom-input > .custom-input__dot {
  @apply bg-primary-1;
}

.input-hidden:disabled + .custom-input > .custom-input__dot {
  @apply bg-cloud-2;
}

.texts {
  @apply flex flex-col;
}

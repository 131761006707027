@import 'libs/northstar/src/styles/pxToRem';

.portal {
  position: absolute;
  background-color: var(--background-primary);
  height: 100vh;
  right: 0;
  top: 0;
  min-width: 600px;
  overflow: hidden;

  .wrapper {
    height: 100%;
    position: relative;
    padding: --px-to-rem(40);
    overflow: auto;
    scrollbar-width: none;

    /* Accomodate the footer */
    padding-bottom: --px-to-rem(64);

    .close-icon {
      position: absolute;

      right: 28px;
      top: 28px;

      color: var(--gray-2);
      scale: 1;

      transition: all 0.1s ease-in-out;

      &:hover {
        color: var(--gray-1);
        scale: 1.05;
      }
    }

    .icon {
      margin-bottom: --px-to-rem(12);
    }

    .header {
      margin-bottom: --px-to-rem(24);

      display: flex;
      flex-direction: column;
      gap: --px-to-rem(4);

      .title {
        font-size: --px-to-rem(24);
        font-weight: 600;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--background-primary);
  display: flex;
  justify-content: flex-end;
  gap: --px-to-rem(16);
  padding: --px-to-rem(8) --px-to-rem(40);
  border-top: 1px solid var(--cloud-3);
}

.more {
  @apply cursor-pointer;
}

.more:hover path:nth-of-type(1) {
  @apply fill-cloud-1;
}

.more:hover path:nth-of-type(2) {
  @apply fill-primary-1;
}

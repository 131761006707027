.portal {
  transform: translate(-50%, 60px) !important;

  @apply absolute w-[460px] bg-white p-6 left-1/2 top-0 rounded-2xl z-40;
}

.form {
  @apply flex w-full justify-end items-center mt-4;
}

.paragraph {
  @apply text-black-2 text-sm font-medium mt-2;
}

button.button--delete {
  @apply text-red-1;
}

button.cancel--button {
  @apply mr-3;
}

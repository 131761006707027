.error-message {
  @apply lowercase text-xs text-red-1;
}

.error-message::first-letter {
  @apply uppercase;
}

.heading {
  @apply font-bold text-sm text-red-1;
}

.container {
  @apply flex flex-col gap-1 px-4 justify-center w-full;

  min-height: 90px;
}

.title {
  @apply max-w-[106px] h-4 bg-cloud-0;
}

.value {
  @apply max-w-[72px] h-9 bg-cloud-0;
}

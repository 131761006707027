.heading {
  @apply text-gray-1 mb-3;
}

.chains__container {
  @apply grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3;
}

.wallets__container {
  @apply grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3;
}

.banner {
  @apply mb-4;
}

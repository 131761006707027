.spinner {
  display: inline-block;
  animation: spin 2s linear infinite;
  border-top: 1px solid;
  border-radius: 50%;

  &--black {
    border-color: var(--black-2);
  }

  &--white {
    border-color: var(--white);
  }

  &--primary {
    border-color: var(--primary-1);
  }

  &--secondary {
    border-color: var(--cloud-3);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card {
  @apply border border-cloud-2 rounded-xl p-4;
}

.row {
  @apply flex items-start justify-between;
}

.title {
  @apply mb-0.5;
}

.subtitle {
  @apply text-gray-1;
}

.info {
  @apply max-w-[240px] w-full mr-3;
}

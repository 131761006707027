@import 'libs/northstar/src/styles/pxToRem';

.container {
  border-radius: --px-to-rem(8);
  border: 1px solid var(--cloud-3);
  background-color: var(--background-primary);
  padding-block: --px-to-rem(4);

  width: --px-to-rem(350);

  overflow: hidden;
  box-shadow: var(--shadow-2);

  box-sizing: content-box;

  .list {
    max-height: var(--organizations-dropdown-list-height);
    overflow-y: auto;
  }
}

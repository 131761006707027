.key-export {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.75rem;
  gap: 0.625rem;
  background: #fff;
  overflow-wrap: break-word;
  color: #7b7d86;
}

.key-export-copy {
  width: 75%;
  position: absolute;
  margin: auto;
}

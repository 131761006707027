.wrapper {
  @apply relative;
}

.tooltip {
  --tooltip-left: calc(50% + 2px);

  @apply absolute left-1/2 transform -translate-x-[--tooltip-left] odd:bg-black-2 w-max rounded px-2 py-1 text-white font-medium text-xs text-center;
}

.tooltipTop {
  @apply -translate-y-full -top-2;
}

.tooltipTop::after {
  --tooltip-left: calc(50% + 2px);

  content: ' ';
  @apply absolute -bottom-1 left-1/2 transform -translate-x-[--tooltip-left] -translate-y-0.5 -rotate-45  origin-top-right bg-black-2  h-2 w-2;
}

.tooltipBottom {
  @apply top-full translate-y-2 z-20;
}

.tooltipBottom::after {
  --tooltip-left: calc(50% + 2px);

  content: ' ';
  @apply absolute -top-3 left-1/2 transform -translate-x-[--tooltip-left] translate-y-full -rotate-45  origin-top-right bg-black-2  h-2 w-2;
}

.token-name__container {
  @apply rounded-lg border border-cloud-2 text-sm text-black-2 font-medium py-3.5 px-3 mb-2 break-words;
}

.token {
  @apply items-center mt-0 mb-2 min-w-full;
}

.alert {
  @apply mb-4;
}

.label {
  @apply mt-4 flex items-center;
}

.paragraph {
  @apply text-xs mt-0 mb-1 text-black-2 font-medium;
}

.consent {
  @apply ml-2 text-xs font-medium text-black-2;
}

.button__container {
  @apply flex w-full justify-end items-center mt-4;
}

.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 px-6 pt-[60px] pb-24;
}

.layout {
  @apply !left-0 !w-full;
}

.container {
  @apply border border-cloud-2 px-4 rounded-xl divide-y flex-col flex mt-2;
}

.banner {
  @apply mb-2;
}

.paper {
  @apply py-3;
}

.wrapper {
  @apply flex justify-between items-start;
}

.panel {
  @apply flex flex-col;
}

.panel--left {
  @apply items-start;
}

.panel--right {
  @apply items-end;
}

.title {
  @apply capitalize;
}

.inline {
  @apply flex items-end justify-center gap-2;
}

.inline--margin {
  @apply mb-2;
}

.inline--end {
  @apply justify-end;
}

.description {
  @apply text-gray-1;
}

.description--small {
  @apply text-xs text-right;
}

.price {
  @apply text-right;
}

.button-wrapper {
  @apply flex gap-2;
}

.divider {
  @apply border-b border-b-cloud-2 pt-4;
}

.button {
  @apply mt-4;
}

.link {
  @apply !px-0;
}

.link--label {
  @apply underline text-primary-1;
}

.month {
  @apply text-gray-1 mb-[0.3rem];
}

.table__header {
  @apply col-span-4;
}

.heading {
  @apply text-xs text-gray-2;
}

.row__container {
  @apply grid grid-cols-12 gap-2 p-1 items-center text-sm;
}

.table__column {
  @apply col-span-3;
}

.table__column_wallet {
  @apply flex flex-row items-center;
}

.row__wallet {
  @apply overflow-hidden text-ellipsis;
}

.row__check {
  @apply border-t border-cloud-2;
}

.row__visit {
  @apply border-t border-cloud-2;
}

.address {
  @apply p-2;
}

.progressbar__container {
  @apply relative flex justify-center items-center mb-2;
}

.progressbar__container--circle p {
  @apply absolute m-auto;
}

.progressbar__container--circle svg {
  @apply -rotate-90;
}

.progressbar__container--circle .progressbar__bg-progress-layer {
  @apply stroke-cloud-1;
}

.progressbar__container--circle .progressbar__front-progress-layer {
  @apply stroke-green-2 stroke-[5];

  transition: stroke-dashoffset 0.3s linear;
}

.progressbar__container--circle .progressbar__percentage-label {
  @apply text-green-1;
}

.progressbar__container--circle .progressbar__completed-icon {
  @apply rotate-0 stroke-green-2 stroke-0 text-green-2 absolute m-auto w-8 h-8;
}

.loadingBarChartContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinnerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255 255 255 / 70%);
}

.spinnerOverlay :global(.spinner) {
  color: #4f46e5;
  width: 24px;
  height: 24px;
}

.section {
  @apply flex flex-col gap-4;
}

.terms__alert {
  @apply my-2 gap-3 flex flex-col;
}

.terms__alert--bold {
  @apply font-bold;
}

.terms__signed {
  @apply inline-flex items-center gap-1;
}

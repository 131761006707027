@import 'libs/northstar/src/styles/pxToRem';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: --px-to-rem(8);
  padding: --px-to-rem(16);
  border-radius: --px-to-rem(8);

  &--info {
    background-color: var(--cloud-1);
  }

  &--warning {
    background-color: var(--orange-3);
  }

  &--error {
    background-color: var(--red-3);
  }

  &--success {
    background-color: var(--green-3);
  }

  &--advance {
    background-color: var(--primary-4);
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  justify-content: center;
  gap: --px-to-rem(2);
  width: 100%;
}

.actionButton {
  margin-top: --px-to-rem(2);
}

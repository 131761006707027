.container {
  @apply flex gap-3 items-center;
}

.children-container {
  @apply text-sm font-medium text-black-2 grow;
}

.trailing {
  @apply font-roboto-sans text-sm font-medium text-black-2;
}

.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 p-6;
}

.buttons {
  @apply flex gap-4 mb-4;
}

.button--refresh {
  @apply disabled:cursor-not-allowed;
}

.container {
  @apply absolute right-0 bottom-0 z-50;

  transform: translateY(100%);
}

.list {
  @apply bg-white rounded-lg py-2;

  box-shadow: 0 4px 8px -6px rgb(24 39 75 / 2%),
    0 8px 24px -4px rgb(24 39 75 / 5%);
}

.item {
  @apply px-2 py-2 cursor-pointer w-full;
}

.portal {
  @apply absolute w-2/3 bg-white h-screen right-0 top-0 px-6 pt-[60px] pb-24;
}

.header {
  @apply flex justify-between items-start;
}

/* stylelint-disable-next-line block-no-empty */
.table__headers {
}

.table__header:first-child {
  @apply col-span-6;
}

.table__header:nth-child(n + 2) {
  @apply col-span-3;
}

.heading {
  @apply text-xs text-gray-2 uppercase;
}

.description__container {
  @apply my-6;
}

.description {
  @apply text-sm text-gray-1;
}

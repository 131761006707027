.container {
  @apply flex flex-1;
}

.row__container {
  @apply w-full;
}

.row {
  @apply flex flex-wrap 2lg:flex-nowrap;
}

.heading {
  @apply mb-2;
}

.subheading {
  @apply mb-2 text-gray-1;
}

.heading--second {
  @apply mt-6;
}

button.button--add {
  @apply mt-3 mb-3;
}

.table__container {
  @apply mt-6 max-w-[750px];
}

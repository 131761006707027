.wrapper {
  @apply flex items-center gap-1.5;
}

.container {
  @apply relative w-4 h-4;
}

.input {
  @apply absolute inset-center opacity-0 w-4 h-4 block cursor-pointer z-10;
}

.checkbox {
  @apply absolute inset-center bg-cloud-1 border border-cloud-1 border-solid rounded w-4 h-4 bg-opacity-100 transition-all duration-200 ease-in-out z-0;
}

.check {
  @apply inset-center w-3 h-3 opacity-0 transition-opacity duration-200;
}

.input:checked + .checkbox {
  @apply bg-primary-1 border-primary-1;
}

.input:checked + .checkbox > .check {
  @apply !opacity-100;
}

.input:disabled {
  @apply pointer-events-none bg-cloud-2;
}

.input:disabled:checked + .checkbox {
  @apply bg-primary-3 border-primary-3 cursor-not-allowed;
}

.input:disabled:checked + .checkbox > .check > path {
  @apply fill-primary-1;
}

.input:disabled + .checkbox {
  @apply cursor-not-allowed;
}

.label {
  @apply text-sm;
}

.rpc__container {
  @apply my-2;
}

.rpc__test--success {
  @apply text-green-1;
}

.rpc__test--failed {
  @apply text-red-1;
}

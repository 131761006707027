@import 'libs/northstar/src/styles/pxToRem';

.section {
  border-radius: --px-to-rem(16);
  border: 1px solid var(--cloud-3);
  background: var(--dashboard-white);

  padding: --px-to-rem(24);

  display: flex;
  justify-content: space-between;
  gap: --px-to-rem(32);

  .left,
  .right {
    width: 100%;
    overflow: auto;
  }

  .left {
    max-width: --px-to-rem(400);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: --px-to-rem(12);

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: --px-to-rem(2);

      .helpButton {
        margin-top: --px-to-rem(10);
      }
    }

    .alerts {
      display: flex;
      flex-direction: column;
      gap: --px-to-rem(4);

      &--top {
        margin-bottom: auto;
      }
    }
  }
}

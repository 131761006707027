.container {
  @apply mt-4 first-of-type:mt-6;
}

.card {
  @apply w-full;
}

.wrapper {
  @apply flex gap-6 min-h-[86px];
}

.counter {
  @apply w-1/3;
}

.counter__name {
  @apply text-gray-1;
}

.link {
  @apply flex gap-2 w-max mb-3 items-center;
}

.arrow-icon {
  @apply rotate-[-90deg] w-3;
}

.arrow-icon path {
  @apply fill-gray-2;
}

.empty {
  @apply text-gray-1;
}

.value {
  @apply flex items-end min-h-[36px];
}

.value--max-amount {
  @apply leading-9;
}

.value--disabled {
  @apply text-cloud-2;
}

.progress {
  @apply h-1 bg-cloud-2 rounded-full w-full max-w-[250px] mb-1.5;
}

.progress__value {
  @apply h-full bg-primary-1 rounded-full;

  max-width: 100%;
}

.progress__value--warning {
  @apply bg-orange-1;
}

.progress__value--error {
  @apply bg-red-1;
}

.progress__value--over-limit {
  @apply bg-black;
}

.upgrade {
  @apply font-bold text-primary-1 underline;
}

.onboarding-done-section__container {
  @apply flex flex-col;
}

.link {
  @apply mb-3;
}

.banner {
  @apply mb-4;
}

.subtitle {
  @apply mt-0.5 text-gray-1 font-light text-sm;
}

.integration {
  @apply mt-6;
}

.integration__grid {
  @apply my-3 grid grid-cols-3 gap-x-4 gap-y-3;
}

.gift-card {
  @apply mb-0 mt-4;
}

.alerts {
  @apply flex gap-2 flex-col mt-4;
}
